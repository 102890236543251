
.icon-achievement {
	background-image: url(./achievement.png);
	width: 40px;
	height: 40px;
}
.icon-achievement-000104 { background-position: 0px 0px }
.icon-achievement-000109 { background-position: -40px 0px }
.icon-achievement-000115 { background-position: 0px -40px }
.icon-achievement-000116 { background-position: -40px -40px }
.icon-achievement-000119 { background-position: -80px 0px }
.icon-achievement-000121 { background-position: -80px -40px }
.icon-achievement-000151 { background-position: 0px -80px }
.icon-achievement-000156 { background-position: -40px -80px }
.icon-achievement-000158 { background-position: -80px -80px }
.icon-achievement-000161 { background-position: -120px 0px }
.icon-achievement-000210 { background-position: -120px -40px }
.icon-achievement-000213 { background-position: -120px -80px }
.icon-achievement-000254 { background-position: 0px -120px }
.icon-achievement-000259 { background-position: -40px -120px }
.icon-achievement-000266 { background-position: -80px -120px }
.icon-achievement-000317 { background-position: -120px -120px }
.icon-achievement-000407 { background-position: -160px 0px }
.icon-achievement-000411 { background-position: -160px -40px }
.icon-achievement-000452 { background-position: -160px -80px }
.icon-achievement-000459 { background-position: -160px -120px }
.icon-achievement-000463 { background-position: 0px -160px }
.icon-achievement-000467 { background-position: -40px -160px }
.icon-achievement-000468 { background-position: -80px -160px }
.icon-achievement-000501 { background-position: -120px -160px }
.icon-achievement-000510 { background-position: -160px -160px }
.icon-achievement-000613 { background-position: -200px 0px }
.icon-achievement-000618 { background-position: -200px -40px }
.icon-achievement-000801 { background-position: -200px -80px }
.icon-achievement-000803 { background-position: -200px -120px }
.icon-achievement-000821 { background-position: -200px -160px }
.icon-achievement-000866 { background-position: 0px -200px }
.icon-achievement-000869 { background-position: -40px -200px }
.icon-achievement-000903 { background-position: -80px -200px }
.icon-achievement-000904 { background-position: -120px -200px }
.icon-achievement-000905 { background-position: -160px -200px }
.icon-achievement-001001 { background-position: -200px -200px }
.icon-achievement-001002 { background-position: -240px 0px }
.icon-achievement-001006 { background-position: -240px -40px }
.icon-achievement-001015 { background-position: -240px -80px }
.icon-achievement-001019 { background-position: -240px -120px }
.icon-achievement-001020 { background-position: -240px -160px }
.icon-achievement-001021 { background-position: -240px -200px }
.icon-achievement-001029 { background-position: 0px -240px }
.icon-achievement-001052 { background-position: -40px -240px }
.icon-achievement-001056 { background-position: -80px -240px }
.icon-achievement-001065 { background-position: -120px -240px }
.icon-achievement-001069 { background-position: -160px -240px }
.icon-achievement-001070 { background-position: -200px -240px }
.icon-achievement-001071 { background-position: -240px -240px }
.icon-achievement-001079 { background-position: -280px 0px }
.icon-achievement-001098 { background-position: -280px -40px }
.icon-achievement-001101 { background-position: -280px -80px }
.icon-achievement-001102 { background-position: -280px -120px }
.icon-achievement-001103 { background-position: -280px -160px }
.icon-achievement-001104 { background-position: -280px -200px }
.icon-achievement-001112 { background-position: -280px -240px }
.icon-achievement-001113 { background-position: 0px -280px }
.icon-achievement-001115 { background-position: -40px -280px }
.icon-achievement-001116 { background-position: -80px -280px }
.icon-achievement-001118 { background-position: -120px -280px }
.icon-achievement-001126 { background-position: -160px -280px }
.icon-achievement-001133 { background-position: -200px -280px }
.icon-achievement-001138 { background-position: -240px -280px }
.icon-achievement-001141 { background-position: -280px -280px }
.icon-achievement-001503 { background-position: -320px 0px }
.icon-achievement-001524 { background-position: -320px -40px }
.icon-achievement-001553 { background-position: -320px -80px }
.icon-achievement-001574 { background-position: -320px -120px }
.icon-achievement-001603 { background-position: -320px -160px }
.icon-achievement-001625 { background-position: -320px -200px }
.icon-achievement-001653 { background-position: -320px -240px }
.icon-achievement-001676 { background-position: -320px -280px }
.icon-achievement-001703 { background-position: 0px -320px }
.icon-achievement-001724 { background-position: -40px -320px }
.icon-achievement-001753 { background-position: -80px -320px }
.icon-achievement-001774 { background-position: -120px -320px }
.icon-achievement-001803 { background-position: -160px -320px }
.icon-achievement-001825 { background-position: -200px -320px }
.icon-achievement-001853 { background-position: -240px -320px }
.icon-achievement-001875 { background-position: -280px -320px }
.icon-achievement-001901 { background-position: -320px -320px }
.icon-achievement-001902 { background-position: -360px 0px }
.icon-achievement-001903 { background-position: -360px -40px }
.icon-achievement-001954 { background-position: -360px -80px }
.icon-achievement-001955 { background-position: -360px -120px }
.icon-achievement-001981 { background-position: -360px -160px }
.icon-achievement-001982 { background-position: -360px -200px }
.icon-achievement-001986 { background-position: -360px -240px }
.icon-achievement-001987 { background-position: -360px -280px }
.icon-achievement-001988 { background-position: -360px -320px }
.icon-achievement-001995 { background-position: 0px -360px }
.icon-achievement-001997 { background-position: -40px -360px }
.icon-achievement-002502 { background-position: -80px -360px }
.icon-achievement-002505 { background-position: -120px -360px }
.icon-achievement-002509 { background-position: -160px -360px }
.icon-achievement-002513 { background-position: -200px -360px }
.icon-achievement-002516 { background-position: -240px -360px }
.icon-achievement-002517 { background-position: -280px -360px }
.icon-achievement-002533 { background-position: -320px -360px }
.icon-achievement-002535 { background-position: -360px -360px }
.icon-achievement-002542 { background-position: -400px 0px }
.icon-achievement-002547 { background-position: -400px -40px }
.icon-achievement-002551 { background-position: -400px -80px }
.icon-achievement-002553 { background-position: -400px -120px }
.icon-achievement-002555 { background-position: -400px -160px }
.icon-achievement-002560 { background-position: -400px -200px }
.icon-achievement-002565 { background-position: -400px -240px }
.icon-achievement-002567 { background-position: -400px -280px }
.icon-achievement-002568 { background-position: -400px -320px }
.icon-achievement-002601 { background-position: -400px -360px }
.icon-achievement-002603 { background-position: 0px -400px }
.icon-achievement-002627 { background-position: -40px -400px }
.icon-achievement-002629 { background-position: -80px -400px }
.icon-achievement-002633 { background-position: -120px -400px }
.icon-achievement-002636 { background-position: -160px -400px }
.icon-achievement-002638 { background-position: -200px -400px }
.icon-achievement-002645 { background-position: -240px -400px }
.icon-achievement-002651 { background-position: -280px -400px }
.icon-achievement-002656 { background-position: -320px -400px }
.icon-achievement-002661 { background-position: -360px -400px }
.icon-achievement-002667 { background-position: -400px -400px }
.icon-achievement-002669 { background-position: -440px 0px }
.icon-achievement-002676 { background-position: -440px -40px }
.icon-achievement-002680 { background-position: -440px -80px }
.icon-achievement-002682 { background-position: -440px -120px }
.icon-achievement-002684 { background-position: -440px -160px }
.icon-achievement-002685 { background-position: -440px -200px }
.icon-achievement-002686 { background-position: -440px -240px }
.icon-achievement-002693 { background-position: -440px -280px }
.icon-achievement-002697 { background-position: -440px -320px }
.icon-achievement-002698 { background-position: -440px -360px }
.icon-achievement-002731 { background-position: -440px -400px }
.icon-achievement-002732 { background-position: 0px -440px }
.icon-achievement-002737 { background-position: -40px -440px }
.icon-achievement-002801 { background-position: -80px -440px }
.icon-achievement-002802 { background-position: -120px -440px }
.icon-achievement-002806 { background-position: -160px -440px }
.icon-achievement-002808 { background-position: -200px -440px }
.icon-achievement-002981 { background-position: -240px -440px }
.icon-achievement-003004 { background-position: -280px -440px }
.icon-achievement-003032 { background-position: -320px -440px }
.icon-achievement-003054 { background-position: -360px -440px }
.icon-achievement-003071 { background-position: -400px -440px }
.icon-achievement-003116 { background-position: -440px -440px }
.icon-achievement-003120 { background-position: -480px 0px }
.icon-achievement-003122 { background-position: -480px -40px }
.icon-achievement-003126 { background-position: -480px -80px }
.icon-achievement-003132 { background-position: -480px -120px }
.icon-achievement-003139 { background-position: -480px -160px }
.icon-achievement-003218 { background-position: -480px -200px }
.icon-achievement-003226 { background-position: -480px -240px }
.icon-achievement-003251 { background-position: -480px -280px }
.icon-achievement-003253 { background-position: -480px -320px }
.icon-achievement-003284 { background-position: -480px -360px }
.icon-achievement-003308 { background-position: -480px -400px }
.icon-achievement-003321 { background-position: -480px -440px }
.icon-achievement-003406 { background-position: 0px -480px }
.icon-achievement-003501 { background-position: -40px -480px }
.icon-achievement-003564 { background-position: -80px -480px }
.icon-achievement-004186 { background-position: -120px -480px }
.icon-achievement-004276 { background-position: -160px -480px }
.icon-achievement-004764 { background-position: -200px -480px }
.icon-achievement-009001 { background-position: -240px -480px }
.icon-achievement-009011 { background-position: -280px -480px }
.icon-achievement-009014 { background-position: -320px -480px }
.icon-achievement-020201 { background-position: -360px -480px }
.icon-achievement-020202 { background-position: -400px -480px }
.icon-achievement-020203 { background-position: -440px -480px }
.icon-achievement-020204 { background-position: -480px -480px }
.icon-achievement-020206 { background-position: -520px 0px }
.icon-achievement-020207 { background-position: -520px -40px }
.icon-achievement-020208 { background-position: -520px -80px }
.icon-achievement-020209 { background-position: -520px -120px }
.icon-achievement-020210 { background-position: -520px -160px }
.icon-achievement-020211 { background-position: -520px -200px }
.icon-achievement-020212 { background-position: -520px -240px }
.icon-achievement-020711 { background-position: -520px -280px }
.icon-achievement-021237 { background-position: -520px -320px }
.icon-achievement-021483 { background-position: -520px -360px }
.icon-achievement-021484 { background-position: -520px -400px }
.icon-achievement-021817 { background-position: -520px -440px }
.icon-achievement-021901 { background-position: -520px -480px }
.icon-achievement-022422 { background-position: 0px -520px }
.icon-achievement-022424 { background-position: -40px -520px }
.icon-achievement-024093 { background-position: -80px -520px }
.icon-achievement-024113 { background-position: -120px -520px }
.icon-achievement-024208 { background-position: -160px -520px }
.icon-achievement-024414 { background-position: -200px -520px }
.icon-achievement-025410 { background-position: -240px -520px }
.icon-achievement-025507 { background-position: -280px -520px }
.icon-achievement-025903 { background-position: -320px -520px }
.icon-achievement-025911 { background-position: -360px -520px }
.icon-achievement-025919 { background-position: -400px -520px }
.icon-achievement-025935 { background-position: -440px -520px }
.icon-achievement-025945 { background-position: -480px -520px }
.icon-achievement-025987 { background-position: -520px -520px }
.icon-achievement-026001 { background-position: -560px 0px }
.icon-achievement-026002 { background-position: -560px -40px }
.icon-achievement-026003 { background-position: -560px -80px }
.icon-achievement-026004 { background-position: -560px -120px }
.icon-achievement-026005 { background-position: -560px -160px }
.icon-achievement-026006 { background-position: -560px -200px }
.icon-achievement-026007 { background-position: -560px -240px }
.icon-achievement-026008 { background-position: -560px -280px }
.icon-achievement-026009 { background-position: -560px -320px }
.icon-achievement-026010 { background-position: -560px -360px }
.icon-achievement-026011 { background-position: -560px -400px }
.icon-achievement-026012 { background-position: -560px -440px }
.icon-achievement-026037 { background-position: -560px -480px }
.icon-achievement-026038 { background-position: -560px -520px }
.icon-achievement-026039 { background-position: 0px -560px }
.icon-achievement-026040 { background-position: -40px -560px }
.icon-achievement-026041 { background-position: -80px -560px }
.icon-achievement-026042 { background-position: -120px -560px }
.icon-achievement-026054 { background-position: -160px -560px }
.icon-achievement-026055 { background-position: -200px -560px }
.icon-achievement-026056 { background-position: -240px -560px }
.icon-achievement-026058 { background-position: -280px -560px }
.icon-achievement-026061 { background-position: -320px -560px }
.icon-achievement-026062 { background-position: -360px -560px }
.icon-achievement-026063 { background-position: -400px -560px }
.icon-achievement-026064 { background-position: -440px -560px }
.icon-achievement-026068 { background-position: -480px -560px }
.icon-achievement-026069 { background-position: -520px -560px }
.icon-achievement-026107 { background-position: -560px -560px }
.icon-achievement-026109 { background-position: -600px 0px }
.icon-achievement-026111 { background-position: -600px -40px }
.icon-achievement-026112 { background-position: -600px -80px }
.icon-achievement-026117 { background-position: -600px -120px }
.icon-achievement-026119 { background-position: -600px -160px }
.icon-achievement-026120 { background-position: -600px -200px }
.icon-achievement-026173 { background-position: -600px -240px }
.icon-achievement-026182 { background-position: -600px -280px }
.icon-achievement-026184 { background-position: -600px -320px }
.icon-achievement-026185 { background-position: -600px -360px }
.icon-achievement-026269 { background-position: -600px -400px }
.icon-achievement-026435 { background-position: -600px -440px }
.icon-achievement-026551 { background-position: -600px -480px }
.icon-achievement-026612 { background-position: -600px -520px }
.icon-achievement-026981 { background-position: -600px -560px }
.icon-achievement-026995 { background-position: 0px -600px }
.icon-achievement-027001 { background-position: -40px -600px }
.icon-achievement-027463 { background-position: -80px -600px }
.icon-achievement-027641 { background-position: -120px -600px }
.icon-achievement-027645 { background-position: -160px -600px }
.icon-achievement-027646 { background-position: -200px -600px }
.icon-achievement-027647 { background-position: -240px -600px }
.icon-achievement-027661 { background-position: -280px -600px }
.icon-achievement-027672 { background-position: -320px -600px }
.icon-achievement-027961 { background-position: -360px -600px }
.icon-achievement-028090 { background-position: -400px -600px }
.icon-achievement-029011 { background-position: -440px -600px }
.icon-achievement-029023 { background-position: -480px -600px }
.icon-achievement-029040 { background-position: -520px -600px }
.icon-achievement-029110 { background-position: -560px -600px }
.icon-achievement-029118 { background-position: -600px -600px }
.icon-achievement-029130 { background-position: -640px 0px }
.icon-achievement-029145 { background-position: -640px -40px }
.icon-achievement-029737 { background-position: -640px -80px }
.icon-achievement-029748 { background-position: -640px -120px }
.icon-achievement-029755 { background-position: -640px -160px }
.icon-achievement-029865 { background-position: -640px -200px }
.icon-achievement-029932 { background-position: -640px -240px }
.icon-achievement-030408 { background-position: -640px -280px }
.icon-achievement-030446 { background-position: -640px -320px }
.icon-achievement-030537 { background-position: -640px -360px }
.icon-achievement-030544 { background-position: -640px -400px }
.icon-achievement-030545 { background-position: -640px -440px }
.icon-achievement-030547 { background-position: -640px -480px }
.icon-achievement-030551 { background-position: -640px -520px }
.icon-achievement-030552 { background-position: -640px -560px }
.icon-achievement-030555 { background-position: -640px -600px }
.icon-achievement-030575 { background-position: 0px -640px }
.icon-achievement-030586 { background-position: -40px -640px }
.icon-achievement-030598 { background-position: -80px -640px }
.icon-achievement-030620 { background-position: -120px -640px }
.icon-achievement-030629 { background-position: -160px -640px }
.icon-achievement-030633 { background-position: -200px -640px }
.icon-achievement-030654 { background-position: -240px -640px }
.icon-achievement-030660 { background-position: -280px -640px }
.icon-achievement-030665 { background-position: -320px -640px }
.icon-achievement-030670 { background-position: -360px -640px }
.icon-achievement-030819 { background-position: -400px -640px }
.icon-achievement-030825 { background-position: -440px -640px }
.icon-achievement-030829 { background-position: -480px -640px }
.icon-achievement-030834 { background-position: -520px -640px }
.icon-achievement-031004 { background-position: -560px -640px }
.icon-achievement-031034 { background-position: -600px -640px }
.icon-achievement-031103 { background-position: -640px -640px }
.icon-achievement-031107 { background-position: -680px 0px }
.icon-achievement-031112 { background-position: -680px -40px }
.icon-achievement-031113 { background-position: -680px -80px }
.icon-achievement-031115 { background-position: -680px -120px }
.icon-achievement-031119 { background-position: -680px -160px }
.icon-achievement-031120 { background-position: -680px -200px }
.icon-achievement-031121 { background-position: -680px -240px }
.icon-achievement-031142 { background-position: -680px -280px }
.icon-achievement-031152 { background-position: -680px -320px }
.icon-achievement-031164 { background-position: -680px -360px }
.icon-achievement-031186 { background-position: -680px -400px }
.icon-achievement-031194 { background-position: -680px -440px }
.icon-achievement-031198 { background-position: -680px -480px }
.icon-achievement-031204 { background-position: -680px -520px }
.icon-achievement-031208 { background-position: -680px -560px }
.icon-achievement-031228 { background-position: -680px -600px }
.icon-achievement-031234 { background-position: -680px -640px }
.icon-achievement-031239 { background-position: 0px -680px }
.icon-achievement-031244 { background-position: -40px -680px }
.icon-achievement-031409 { background-position: -80px -680px }
.icon-achievement-031440 { background-position: -120px -680px }
.icon-achievement-031508 { background-position: -160px -680px }
.icon-achievement-031516 { background-position: -200px -680px }
.icon-achievement-031517 { background-position: -240px -680px }
.icon-achievement-031519 { background-position: -280px -680px }
.icon-achievement-031523 { background-position: -320px -680px }
.icon-achievement-031524 { background-position: -360px -680px }
.icon-achievement-031528 { background-position: -400px -680px }
.icon-achievement-031541 { background-position: -440px -680px }
.icon-achievement-031560 { background-position: -480px -680px }
.icon-achievement-031572 { background-position: -520px -680px }
.icon-achievement-031593 { background-position: -560px -680px }
.icon-achievement-031606 { background-position: -600px -680px }
.icon-achievement-031610 { background-position: -640px -680px }
.icon-achievement-031630 { background-position: -680px -680px }
.icon-achievement-031636 { background-position: -720px 0px }
.icon-achievement-031641 { background-position: -720px -40px }
.icon-achievement-031646 { background-position: -720px -80px }
.icon-achievement-031816 { background-position: -720px -120px }
.icon-achievement-031842 { background-position: -720px -160px }
.icon-achievement-031908 { background-position: -720px -200px }
.icon-achievement-031916 { background-position: -720px -240px }
.icon-achievement-031917 { background-position: -720px -280px }
.icon-achievement-031919 { background-position: -720px -320px }
.icon-achievement-031923 { background-position: -720px -360px }
.icon-achievement-031924 { background-position: -720px -400px }
.icon-achievement-031928 { background-position: -720px -440px }
.icon-achievement-031947 { background-position: -720px -480px }
.icon-achievement-031960 { background-position: -720px -520px }
.icon-achievement-031974 { background-position: -720px -560px }
.icon-achievement-031995 { background-position: -720px -600px }
.icon-achievement-032019 { background-position: -720px -640px }
.icon-achievement-032025 { background-position: -720px -680px }
.icon-achievement-032029 { background-position: 0px -720px }
.icon-achievement-032034 { background-position: -40px -720px }
.icon-achievement-032204 { background-position: -80px -720px }
.icon-achievement-032238 { background-position: -120px -720px }
.icon-achievement-032304 { background-position: -160px -720px }
.icon-achievement-032311 { background-position: -200px -720px }
.icon-achievement-032312 { background-position: -240px -720px }
.icon-achievement-032314 { background-position: -280px -720px }
.icon-achievement-032318 { background-position: -320px -720px }
.icon-achievement-032319 { background-position: -360px -720px }
.icon-achievement-032320 { background-position: -400px -720px }
.icon-achievement-032342 { background-position: -440px -720px }
.icon-achievement-032351 { background-position: -480px -720px }
.icon-achievement-032363 { background-position: -520px -720px }
.icon-achievement-032386 { background-position: -560px -720px }
.icon-achievement-032393 { background-position: -600px -720px }
.icon-achievement-032397 { background-position: -640px -720px }
.icon-achievement-032444 { background-position: -680px -720px }
.icon-achievement-032448 { background-position: -720px -720px }
.icon-achievement-032454 { background-position: -760px 0px }
.icon-achievement-032459 { background-position: -760px -40px }
.icon-achievement-032464 { background-position: -760px -80px }
.icon-achievement-032611 { background-position: -760px -120px }
.icon-achievement-032626 { background-position: -760px -160px }
.icon-achievement-032684 { background-position: -760px -200px }
.icon-achievement-032693 { background-position: -760px -240px }
.icon-achievement-032695 { background-position: -760px -280px }
.icon-achievement-032699 { background-position: -760px -320px }
.icon-achievement-032700 { background-position: -760px -360px }
.icon-achievement-032703 { background-position: -760px -400px }
.icon-achievement-032724 { background-position: -760px -440px }
.icon-achievement-032735 { background-position: -760px -480px }
.icon-achievement-032747 { background-position: -760px -520px }
.icon-achievement-032769 { background-position: -760px -560px }
.icon-achievement-032777 { background-position: -760px -600px }
.icon-achievement-032781 { background-position: -760px -640px }
.icon-achievement-032806 { background-position: -760px -680px }
.icon-achievement-032862 { background-position: -760px -720px }
.icon-achievement-032863 { background-position: 0px -760px }
.icon-achievement-032869 { background-position: -40px -760px }
.icon-achievement-032874 { background-position: -80px -760px }
.icon-achievement-032879 { background-position: -120px -760px }
.icon-achievement-033012 { background-position: -160px -760px }
.icon-achievement-033052 { background-position: -200px -760px }
.icon-achievement-033061 { background-position: -240px -760px }
.icon-achievement-033063 { background-position: -280px -760px }
.icon-achievement-033068 { background-position: -320px -760px }
.icon-achievement-033069 { background-position: -360px -760px }
.icon-achievement-033071 { background-position: -400px -760px }
.icon-achievement-033093 { background-position: -440px -760px }
.icon-achievement-033103 { background-position: -480px -760px }
.icon-achievement-033116 { background-position: -520px -760px }
.icon-achievement-033140 { background-position: -560px -760px }
.icon-achievement-033148 { background-position: -600px -760px }
.icon-achievement-033152 { background-position: -640px -760px }
.icon-achievement-033213 { background-position: -680px -760px }
.icon-achievement-033214 { background-position: -720px -760px }
.icon-achievement-033221 { background-position: -760px -760px }
.icon-achievement-033222 { background-position: -800px 0px }
.icon-achievement-033237 { background-position: -800px -40px }
.icon-achievement-033240 { background-position: -800px -80px }
.icon-achievement-033257 { background-position: -800px -120px }
.icon-achievement-033258 { background-position: -800px -160px }
.icon-achievement-033281 { background-position: -800px -200px }
.icon-achievement-033282 { background-position: -800px -240px }
.icon-achievement-033321 { background-position: -800px -280px }
.icon-achievement-033322 { background-position: -800px -320px }
.icon-achievement-033361 { background-position: -800px -360px }
.icon-achievement-033362 { background-position: -800px -400px }
.icon-achievement-033373 { background-position: -800px -440px }
.icon-achievement-033378 { background-position: -800px -480px }
.icon-achievement-033381 { background-position: -800px -520px }
.icon-achievement-033382 { background-position: -800px -560px }
.icon-achievement-033391 { background-position: -800px -600px }
.icon-achievement-033392 { background-position: -800px -640px }
.icon-achievement-033401 { background-position: -800px -680px }
.icon-achievement-033457 { background-position: -800px -720px }
.icon-achievement-033458 { background-position: -800px -760px }
.icon-achievement-033538 { background-position: 0px -800px }
.icon-achievement-033539 { background-position: -40px -800px }
.icon-achievement-033552 { background-position: -80px -800px }
.icon-achievement-033553 { background-position: -120px -800px }
.icon-achievement-033554 { background-position: -160px -800px }
.icon-achievement-033555 { background-position: -200px -800px }
.icon-achievement-033558 { background-position: -240px -800px }
.icon-achievement-033559 { background-position: -280px -800px }
.icon-achievement-033566 { background-position: -320px -800px }
.icon-achievement-033567 { background-position: -360px -800px }
.icon-achievement-033568 { background-position: -400px -800px }
.icon-achievement-033569 { background-position: -440px -800px }
.icon-achievement-033570 { background-position: -480px -800px }
.icon-achievement-033571 { background-position: -520px -800px }
.icon-achievement-033605 { background-position: -560px -800px }
.icon-achievement-033611 { background-position: -600px -800px }
.icon-achievement-033615 { background-position: -640px -800px }
.icon-achievement-033620 { background-position: -680px -800px }
.icon-achievement-033801 { background-position: -720px -800px }
.icon-achievement-033855 { background-position: -760px -800px }
.icon-achievement-033891 { background-position: -800px -800px }
.icon-achievement-033898 { background-position: -840px 0px }
.icon-achievement-033899 { background-position: -840px -40px }
.icon-achievement-033901 { background-position: -840px -80px }
.icon-achievement-033905 { background-position: -840px -120px }
.icon-achievement-033906 { background-position: -840px -160px }
.icon-achievement-033907 { background-position: -840px -200px }
.icon-achievement-033921 { background-position: -840px -240px }
.icon-achievement-033938 { background-position: -840px -280px }
.icon-achievement-033950 { background-position: -840px -320px }
.icon-achievement-033970 { background-position: -840px -360px }
.icon-achievement-033980 { background-position: -840px -400px }
.icon-achievement-033984 { background-position: -840px -440px }
.icon-achievement-034203 { background-position: -840px -480px }
.icon-achievement-034264 { background-position: -840px -520px }
.icon-achievement-034271 { background-position: -840px -560px }
.icon-achievement-034272 { background-position: -840px -600px }
.icon-achievement-034274 { background-position: -840px -640px }
.icon-achievement-034278 { background-position: -840px -680px }
.icon-achievement-034279 { background-position: -840px -720px }
.icon-achievement-034281 { background-position: -840px -760px }
.icon-achievement-034299 { background-position: -840px -800px }
.icon-achievement-034314 { background-position: 0px -840px }
.icon-achievement-034327 { background-position: -40px -840px }
.icon-achievement-034352 { background-position: -80px -840px }
.icon-achievement-034358 { background-position: -120px -840px }
.icon-achievement-034362 { background-position: -160px -840px }
.icon-achievement-034382 { background-position: -200px -840px }
.icon-achievement-034388 { background-position: -240px -840px }
.icon-achievement-034393 { background-position: -280px -840px }
.icon-achievement-034398 { background-position: -320px -840px }
.icon-achievement-034404 { background-position: -360px -840px }
.icon-achievement-034408 { background-position: -400px -840px }
.icon-achievement-034437 { background-position: -440px -840px }
.icon-achievement-034449 { background-position: -480px -840px }
.icon-achievement-034468 { background-position: -520px -840px }
.icon-achievement-034478 { background-position: -560px -840px }
.icon-achievement-034484 { background-position: -600px -840px }
.icon-achievement-034488 { background-position: -640px -840px }
.icon-achievement-034493 { background-position: -680px -840px }
.icon-achievement-034607 { background-position: -720px -840px }
.icon-achievement-034664 { background-position: -760px -840px }
.icon-achievement-034672 { background-position: -800px -840px }
.icon-achievement-034673 { background-position: -840px -840px }
.icon-achievement-034675 { background-position: -880px 0px }
.icon-achievement-034679 { background-position: -880px -40px }
.icon-achievement-034680 { background-position: -880px -80px }
.icon-achievement-034681 { background-position: -880px -120px }
.icon-achievement-034693 { background-position: -880px -160px }
.icon-achievement-034808 { background-position: -880px -200px }
.icon-achievement-034864 { background-position: -880px -240px }
.icon-achievement-034871 { background-position: -880px -280px }
.icon-achievement-034872 { background-position: -880px -320px }
.icon-achievement-034874 { background-position: -880px -360px }
.icon-achievement-034878 { background-position: -880px -400px }
.icon-achievement-034879 { background-position: -880px -440px }
.icon-achievement-034880 { background-position: -880px -480px }
.icon-achievement-034900 { background-position: -880px -520px }
.icon-achievement-034911 { background-position: -880px -560px }
.icon-achievement-034923 { background-position: -880px -600px }
.icon-achievement-034945 { background-position: -880px -640px }
.icon-achievement-034952 { background-position: -880px -680px }
.icon-achievement-034956 { background-position: -880px -720px }
.icon-achievement-034976 { background-position: -880px -760px }
.icon-achievement-034984 { background-position: -880px -800px }
.icon-achievement-034986 { background-position: -880px -840px }
.icon-achievement-034991 { background-position: 0px -880px }
.icon-achievement-035001 { background-position: -40px -880px }
.icon-achievement-035010 { background-position: -80px -880px }
.icon-achievement-035015 { background-position: -120px -880px }
.icon-achievement-035040 { background-position: -160px -880px }
.icon-achievement-035042 { background-position: -200px -880px }
.icon-achievement-035106 { background-position: -240px -880px }
.icon-achievement-035110 { background-position: -280px -880px }
.icon-achievement-035114 { background-position: -320px -880px }
.icon-achievement-035139 { background-position: -360px -880px }
.icon-achievement-035141 { background-position: -400px -880px }
.icon-achievement-035149 { background-position: -440px -880px }
.icon-achievement-035203 { background-position: -480px -880px }
.icon-achievement-035209 { background-position: -520px -880px }
.icon-achievement-035214 { background-position: -560px -880px }
.icon-achievement-035239 { background-position: -600px -880px }
.icon-achievement-035241 { background-position: -640px -880px }
.icon-achievement-035249 { background-position: -680px -880px }
.icon-achievement-035301 { background-position: -720px -880px }
.icon-achievement-035309 { background-position: -760px -880px }
.icon-achievement-035313 { background-position: -800px -880px }
.icon-achievement-035338 { background-position: -840px -880px }
.icon-achievement-035340 { background-position: -880px -880px }
.icon-achievement-035348 { background-position: -920px 0px }
.icon-achievement-035404 { background-position: -920px -40px }
.icon-achievement-035409 { background-position: -920px -80px }
.icon-achievement-035414 { background-position: -920px -120px }
.icon-achievement-035439 { background-position: -920px -160px }
.icon-achievement-035441 { background-position: -920px -200px }
.icon-achievement-035449 { background-position: -920px -240px }
.icon-achievement-035501 { background-position: -920px -280px }
.icon-achievement-035510 { background-position: -920px -320px }
.icon-achievement-035514 { background-position: -920px -360px }
.icon-achievement-035539 { background-position: -920px -400px }
.icon-achievement-035541 { background-position: -920px -440px }
.icon-achievement-035549 { background-position: -920px -480px }
.icon-achievement-035604 { background-position: -920px -520px }
.icon-achievement-035609 { background-position: -920px -560px }
.icon-achievement-035613 { background-position: -920px -600px }
.icon-achievement-035638 { background-position: -920px -640px }
.icon-achievement-035640 { background-position: -920px -680px }
.icon-achievement-035648 { background-position: -920px -720px }
.icon-achievement-035704 { background-position: -920px -760px }
.icon-achievement-035711 { background-position: -920px -800px }
.icon-achievement-035716 { background-position: -920px -840px }
.icon-achievement-035741 { background-position: -920px -880px }
.icon-achievement-035743 { background-position: 0px -920px }
.icon-achievement-036005 { background-position: -40px -920px }
.icon-achievement-036026 { background-position: -80px -920px }
.icon-achievement-036041 { background-position: -120px -920px }
.icon-achievement-036048 { background-position: -160px -920px }
.icon-achievement-036050 { background-position: -200px -920px }
.icon-achievement-036054 { background-position: -240px -920px }
.icon-achievement-036075 { background-position: -280px -920px }
.icon-achievement-036081 { background-position: -320px -920px }
.icon-achievement-036085 { background-position: -360px -920px }
.icon-achievement-036090 { background-position: -400px -920px }
.icon-achievement-036205 { background-position: -440px -920px }
.icon-achievement-036224 { background-position: -480px -920px }
.icon-achievement-036244 { background-position: -520px -920px }
.icon-achievement-036247 { background-position: -560px -920px }
.icon-achievement-036249 { background-position: -600px -920px }
.icon-achievement-036253 { background-position: -640px -920px }
.icon-achievement-036274 { background-position: -680px -920px }
.icon-achievement-036280 { background-position: -720px -920px }
.icon-achievement-036284 { background-position: -760px -920px }
.icon-achievement-036289 { background-position: -800px -920px }
.icon-achievement-036412 { background-position: -840px -920px }
.icon-achievement-036427 { background-position: -880px -920px }
.icon-achievement-036431 { background-position: -920px -920px }
.icon-achievement-036468 { background-position: -960px 0px }
.icon-achievement-036481 { background-position: -960px -40px }
.icon-achievement-036502 { background-position: -960px -80px }
.icon-achievement-036507 { background-position: -960px -120px }
.icon-achievement-036509 { background-position: -960px -160px }
.icon-achievement-036513 { background-position: -960px -200px }
.icon-achievement-036533 { background-position: -960px -240px }
.icon-achievement-036539 { background-position: -960px -280px }
.icon-achievement-036543 { background-position: -960px -320px }
.icon-achievement-036548 { background-position: -960px -360px }
.icon-achievement-036701 { background-position: -960px -400px }
.icon-achievement-036747 { background-position: -960px -440px }
.icon-achievement-036751 { background-position: -960px -480px }
.icon-achievement-036769 { background-position: -960px -520px }
.icon-achievement-036781 { background-position: -960px -560px }
.icon-achievement-036801 { background-position: -960px -600px }
.icon-achievement-036806 { background-position: -960px -640px }
.icon-achievement-036808 { background-position: -960px -680px }
.icon-achievement-036812 { background-position: -960px -720px }
.icon-achievement-036832 { background-position: -960px -760px }
.icon-achievement-036838 { background-position: -960px -800px }
.icon-achievement-036842 { background-position: -960px -840px }
.icon-achievement-036847 { background-position: -960px -880px }
.icon-achievement-037027 { background-position: -960px -920px }
.icon-achievement-037030 { background-position: 0px -960px }
.icon-achievement-037040 { background-position: -40px -960px }
.icon-achievement-037048 { background-position: -80px -960px }
.icon-achievement-037051 { background-position: -120px -960px }
.icon-achievement-037056 { background-position: -160px -960px }
.icon-achievement-037231 { background-position: -200px -960px }
.icon-achievement-037232 { background-position: -240px -960px }
.icon-achievement-037242 { background-position: -280px -960px }
.icon-achievement-037250 { background-position: -320px -960px }
.icon-achievement-037254 { background-position: -360px -960px }
.icon-achievement-037259 { background-position: -400px -960px }
.icon-achievement-037430 { background-position: -440px -960px }
.icon-achievement-037435 { background-position: -480px -960px }
.icon-achievement-037639 { background-position: -520px -960px }
.icon-achievement-037640 { background-position: -560px -960px }
.icon-achievement-038003 { background-position: -600px -960px }
.icon-achievement-038011 { background-position: -640px -960px }
.icon-achievement-038016 { background-position: -680px -960px }
.icon-achievement-038041 { background-position: -720px -960px }
.icon-achievement-038103 { background-position: -760px -960px }
.icon-achievement-038110 { background-position: -800px -960px }
.icon-achievement-038114 { background-position: -840px -960px }
.icon-achievement-038139 { background-position: -880px -960px }
.icon-achievement-038149 { background-position: -920px -960px }
.icon-achievement-038203 { background-position: -960px -960px }
.icon-achievement-038211 { background-position: -1000px 0px }
.icon-achievement-038217 { background-position: -1000px -40px }
.icon-achievement-038242 { background-position: -1000px -80px }
.icon-achievement-038301 { background-position: -1000px -120px }
.icon-achievement-038305 { background-position: -1000px -160px }
.icon-achievement-038306 { background-position: -1000px -200px }
.icon-achievement-038403 { background-position: -1000px -240px }
.icon-achievement-038404 { background-position: -1000px -280px }
.icon-achievement-038502 { background-position: -1000px -320px }
.icon-achievement-038506 { background-position: -1000px -360px }
.icon-achievement-038507 { background-position: -1000px -400px }
.icon-achievement-039000 { background-position: -1000px -440px }
.icon-achievement-039004 { background-position: -1000px -480px }
.icon-achievement-039005 { background-position: -1000px -520px }
.icon-achievement-039103 { background-position: -1000px -560px }
.icon-achievement-039104 { background-position: -1000px -600px }
.icon-achievement-039203 { background-position: -1000px -640px }
.icon-achievement-039204 { background-position: -1000px -680px }
.icon-achievement-039302 { background-position: -1000px -720px }
.icon-achievement-039303 { background-position: -1000px -760px }
.icon-achievement-039403 { background-position: -1000px -800px }
.icon-achievement-039404 { background-position: -1000px -840px }
.icon-achievement-039503 { background-position: -1000px -880px }
.icon-achievement-039504 { background-position: -1000px -920px }
.icon-achievement-039602 { background-position: -1000px -960px }
.icon-achievement-039603 { background-position: 0px -1000px }
.icon-achievement-039701 { background-position: -40px -1000px }
.icon-achievement-039705 { background-position: -80px -1000px }
.icon-achievement-039706 { background-position: -120px -1000px }
.icon-achievement-039903 { background-position: -160px -1000px }
.icon-achievement-040039 { background-position: -200px -1000px }
.icon-achievement-040225 { background-position: -240px -1000px }
.icon-achievement-040241 { background-position: -280px -1000px }
.icon-achievement-040248 { background-position: -320px -1000px }
.icon-achievement-040283 { background-position: -360px -1000px }
.icon-achievement-040299 { background-position: -400px -1000px }
.icon-achievement-040312 { background-position: -440px -1000px }
.icon-achievement-040356 { background-position: -480px -1000px }
.icon-achievement-040704 { background-position: -520px -1000px }
.icon-achievement-040873 { background-position: -560px -1000px }
.icon-achievement-040972 { background-position: -600px -1000px }
.icon-achievement-041075 { background-position: -640px -1000px }
.icon-achievement-041099 { background-position: -680px -1000px }
.icon-achievement-041184 { background-position: -720px -1000px }
.icon-achievement-041212 { background-position: -760px -1000px }
.icon-achievement-041220 { background-position: -800px -1000px }
.icon-achievement-041301 { background-position: -840px -1000px }
.icon-achievement-041519 { background-position: -880px -1000px }
.icon-achievement-041750 { background-position: -920px -1000px }
.icon-achievement-041751 { background-position: -960px -1000px }
.icon-achievement-041791 { background-position: -1000px -1000px }
.icon-achievement-041792 { background-position: -1040px 0px }
.icon-achievement-041807 { background-position: -1040px -40px }
.icon-achievement-041827 { background-position: -1040px -80px }
.icon-achievement-041996 { background-position: -1040px -120px }
.icon-achievement-041998 { background-position: -1040px -160px }
.icon-achievement-042351 { background-position: -1040px -200px }
.icon-achievement-042531 { background-position: -1040px -240px }
.icon-achievement-042532 { background-position: -1040px -280px }
.icon-achievement-042538 { background-position: -1040px -320px }
.icon-achievement-042584 { background-position: -1040px -360px }
.icon-achievement-042621 { background-position: -1040px -400px }
.icon-achievement-042671 { background-position: -1040px -440px }
.icon-achievement-042696 { background-position: -1040px -480px }
.icon-achievement-042706 { background-position: -1040px -520px }
.icon-achievement-042818 { background-position: -1040px -560px }
.icon-achievement-042851 { background-position: -1040px -600px }
.icon-achievement-042995 { background-position: -1040px -640px }
.icon-achievement-043579 { background-position: -1040px -680px }
.icon-achievement-043745 { background-position: -1040px -720px }
.icon-achievement-043848 { background-position: -1040px -760px }
.icon-achievement-043858 { background-position: -1040px -800px }
.icon-achievement-043909 { background-position: -1040px -840px }
.icon-achievement-048094 { background-position: -1040px -880px }
.icon-achievement-048108 { background-position: -1040px -920px }
.icon-achievement-048905 { background-position: -1040px -960px }
.icon-achievement-048930 { background-position: -1040px -1000px }
.icon-achievement-050114 { background-position: 0px -1040px }
.icon-achievement-051043 { background-position: -40px -1040px }
.icon-achievement-051139 { background-position: -80px -1040px }
.icon-achievement-051248 { background-position: -120px -1040px }
.icon-achievement-052262 { background-position: -160px -1040px }
.icon-achievement-052583 { background-position: -200px -1040px }
.icon-achievement-052675 { background-position: -240px -1040px }
.icon-achievement-052676 { background-position: -280px -1040px }
.icon-achievement-052677 { background-position: -320px -1040px }
.icon-achievement-052691 { background-position: -360px -1040px }
.icon-achievement-052692 { background-position: -400px -1040px }
.icon-achievement-052714 { background-position: -440px -1040px }
.icon-achievement-052715 { background-position: -480px -1040px }
.icon-achievement-052793 { background-position: -520px -1040px }
.icon-achievement-053100 { background-position: -560px -1040px }
.icon-achievement-053292 { background-position: -600px -1040px }
.icon-achievement-053371 { background-position: -640px -1040px }
.icon-achievement-053406 { background-position: -680px -1040px }
.icon-achievement-053408 { background-position: -720px -1040px }
.icon-achievement-054212 { background-position: -760px -1040px }
.icon-achievement-054911 { background-position: -800px -1040px }
.icon-achievement-055318 { background-position: -840px -1040px }
.icon-achievement-055750 { background-position: -880px -1040px }
.icon-achievement-056554 { background-position: -920px -1040px }
.icon-achievement-056563 { background-position: -960px -1040px }
.icon-achievement-056588 { background-position: -1000px -1040px }
.icon-achievement-056611 { background-position: -1040px -1040px }
.icon-achievement-056635 { background-position: -1080px 0px }
.icon-achievement-056726 { background-position: -1080px -40px }
.icon-achievement-057182 { background-position: -1080px -80px }
.icon-achievement-058042 { background-position: -1080px -120px }
.icon-achievement-058573 { background-position: -1080px -160px }
.icon-achievement-058589 { background-position: -1080px -200px }
.icon-achievement-058627 { background-position: -1080px -240px }
.icon-achievement-058723 { background-position: -1080px -280px }
.icon-achievement-058753 { background-position: -1080px -320px }
.icon-achievement-058804 { background-position: -1080px -360px }
.icon-achievement-059240 { background-position: -1080px -400px }
.icon-achievement-059246 { background-position: -1080px -440px }
.icon-achievement-059401 { background-position: -1080px -480px }
.icon-achievement-059402 { background-position: -1080px -520px }
.icon-achievement-059403 { background-position: -1080px -560px }
.icon-achievement-059429 { background-position: -1080px -600px }
.icon-achievement-059441 { background-position: -1080px -640px }
.icon-achievement-059457 { background-position: -1080px -680px }
.icon-achievement-059469 { background-position: -1080px -720px }
.icon-achievement-059488 { background-position: -1080px -760px }
.icon-achievement-059583 { background-position: -1080px -800px }
.icon-achievement-059584 { background-position: -1080px -840px }
.icon-achievement-059585 { background-position: -1080px -880px }
.icon-achievement-059586 { background-position: -1080px -920px }
.icon-achievement-059587 { background-position: -1080px -960px }
.icon-achievement-059588 { background-position: -1080px -1000px }
.icon-achievement-059589 { background-position: -1080px -1040px }
.icon-achievement-059590 { background-position: 0px -1080px }
.icon-achievement-059591 { background-position: -40px -1080px }
.icon-achievement-059592 { background-position: -80px -1080px }
.icon-achievement-059593 { background-position: -120px -1080px }
.icon-achievement-059594 { background-position: -160px -1080px }
.icon-achievement-059595 { background-position: -200px -1080px }
.icon-achievement-059627 { background-position: -240px -1080px }
.icon-achievement-059680 { background-position: -280px -1080px }
.icon-achievement-059707 { background-position: -320px -1080px }
.icon-achievement-059790 { background-position: -360px -1080px }
.icon-achievement-059791 { background-position: -400px -1080px }
.icon-achievement-059792 { background-position: -440px -1080px }
.icon-achievement-059793 { background-position: -480px -1080px }
.icon-achievement-059820 { background-position: -520px -1080px }
.icon-achievement-059850 { background-position: -560px -1080px }
.icon-achievement-059861 { background-position: -600px -1080px }
.icon-achievement-059876 { background-position: -640px -1080px }
.icon-achievement-059892 { background-position: -680px -1080px }
.icon-achievement-060031 { background-position: -720px -1080px }
.icon-achievement-060033 { background-position: -760px -1080px }
.icon-achievement-060035 { background-position: -800px -1080px }
.icon-achievement-061251 { background-position: -840px -1080px }
.icon-achievement-061253 { background-position: -880px -1080px }
.icon-achievement-061262 { background-position: -920px -1080px }
.icon-achievement-061264 { background-position: -960px -1080px }
.icon-achievement-061265 { background-position: -1000px -1080px }
.icon-achievement-061269 { background-position: -1040px -1080px }
.icon-achievement-061271 { background-position: -1080px -1080px }
.icon-achievement-062801 { background-position: -1120px 0px }
.icon-achievement-062802 { background-position: -1120px -40px }
.icon-achievement-062803 { background-position: -1120px -80px }
.icon-achievement-062804 { background-position: -1120px -120px }
.icon-achievement-062805 { background-position: -1120px -160px }
.icon-achievement-062806 { background-position: -1120px -200px }
.icon-achievement-062807 { background-position: -1120px -240px }
.icon-achievement-062826 { background-position: -1120px -280px }
.icon-achievement-062829 { background-position: -1120px -320px }
.icon-achievement-062901 { background-position: -1120px -360px }
.icon-achievement-062902 { background-position: -1120px -400px }
.icon-achievement-062903 { background-position: -1120px -440px }
.icon-achievement-062904 { background-position: -1120px -480px }
.icon-achievement-062905 { background-position: -1120px -520px }
.icon-achievement-062906 { background-position: -1120px -560px }
.icon-achievement-062907 { background-position: -1120px -600px }
.icon-achievement-062908 { background-position: -1120px -640px }
.icon-achievement-062909 { background-position: -1120px -680px }
.icon-achievement-062910 { background-position: -1120px -720px }
.icon-achievement-062911 { background-position: -1120px -760px }
.icon-achievement-062912 { background-position: -1120px -800px }
.icon-achievement-062913 { background-position: -1120px -840px }
.icon-achievement-062914 { background-position: -1120px -880px }
.icon-achievement-062915 { background-position: -1120px -920px }
.icon-achievement-062916 { background-position: -1120px -960px }
.icon-achievement-062917 { background-position: -1120px -1000px }
.icon-achievement-062918 { background-position: -1120px -1040px }
.icon-achievement-062919 { background-position: -1120px -1080px }
.icon-achievement-062920 { background-position: 0px -1120px }
.icon-achievement-062921 { background-position: -40px -1120px }
.icon-achievement-062922 { background-position: -80px -1120px }
.icon-achievement-062923 { background-position: -120px -1120px }
.icon-achievement-062924 { background-position: -160px -1120px }
.icon-achievement-062925 { background-position: -200px -1120px }
.icon-achievement-062926 { background-position: -240px -1120px }
.icon-achievement-062927 { background-position: -280px -1120px }
.icon-achievement-062928 { background-position: -320px -1120px }
.icon-achievement-062929 { background-position: -360px -1120px }
.icon-achievement-062930 { background-position: -400px -1120px }
.icon-achievement-062931 { background-position: -440px -1120px }
.icon-achievement-062932 { background-position: -480px -1120px }
.icon-achievement-062933 { background-position: -520px -1120px }
.icon-achievement-062934 { background-position: -560px -1120px }
.icon-achievement-062935 { background-position: -600px -1120px }
.icon-achievement-062936 { background-position: -640px -1120px }
.icon-achievement-062937 { background-position: -680px -1120px }
.icon-achievement-062938 { background-position: -720px -1120px }
.icon-achievement-062939 { background-position: -760px -1120px }
.icon-achievement-062951 { background-position: -800px -1120px }
.icon-achievement-062952 { background-position: -840px -1120px }
.icon-achievement-062953 { background-position: -880px -1120px }
.icon-achievement-062955 { background-position: -920px -1120px }
.icon-achievement-062956 { background-position: -960px -1120px }
.icon-achievement-062957 { background-position: -1000px -1120px }
.icon-achievement-062958 { background-position: -1040px -1120px }
.icon-achievement-062959 { background-position: -1080px -1120px }
.icon-achievement-062960 { background-position: -1120px -1120px }
.icon-achievement-062961 { background-position: -1160px 0px }
.icon-achievement-062962 { background-position: -1160px -40px }
.icon-achievement-062963 { background-position: -1160px -80px }
.icon-achievement-062964 { background-position: -1160px -120px }
.icon-achievement-062965 { background-position: -1160px -160px }
.icon-achievement-062966 { background-position: -1160px -200px }
.icon-achievement-062967 { background-position: -1160px -240px }
.icon-achievement-062968 { background-position: -1160px -280px }
.icon-achievement-062969 { background-position: -1160px -320px }
.icon-achievement-062970 { background-position: -1160px -360px }
.icon-achievement-062971 { background-position: -1160px -400px }
.icon-achievement-062972 { background-position: -1160px -440px }
.icon-achievement-062973 { background-position: -1160px -480px }
.icon-achievement-062975 { background-position: -1160px -520px }
.icon-achievement-062976 { background-position: -1160px -560px }
.icon-achievement-062977 { background-position: -1160px -600px }
.icon-achievement-062978 { background-position: -1160px -640px }
.icon-achievement-064420 { background-position: -1160px -680px }
.icon-achievement-064428 { background-position: -1160px -720px }
.icon-achievement-064442 { background-position: -1160px -760px }
.icon-achievement-064452 { background-position: -1160px -800px }
.icon-achievement-064457 { background-position: -1160px -840px }
.icon-achievement-064612 { background-position: -1160px -880px }
.icon-achievement-064645 { background-position: -1160px -920px }
.icon-achievement-064660 { background-position: -1160px -960px }
.icon-achievement-064805 { background-position: -1160px -1000px }
.icon-achievement-064824 { background-position: -1160px -1040px }
.icon-achievement-064836 { background-position: -1160px -1080px }
.icon-achievement-064840 { background-position: -1160px -1120px }
.icon-achievement-064848 { background-position: 0px -1160px }
.icon-achievement-064856 { background-position: -40px -1160px }
.icon-achievement-064858 { background-position: -80px -1160px }
.icon-achievement-064875 { background-position: -120px -1160px }
.icon-achievement-064893 { background-position: -160px -1160px }
.icon-achievement-064902 { background-position: -200px -1160px }
.icon-achievement-064905 { background-position: -240px -1160px }