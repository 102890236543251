
.icon-leveAssignmentType {
	background-image: url(./leveAssignmentType.png);
	width: 32px;
	height: 32px;
}
.icon-leveAssignmentType-062516 { background-position: 0px 0px }
.icon-leveAssignmentType-062517 { background-position: -32px 0px }
.icon-leveAssignmentType-Alchemist { background-position: 0px -32px }
.icon-leveAssignmentType-Armorer { background-position: -32px -32px }
.icon-leveAssignmentType-Battlecraft { background-position: -64px 0px }
.icon-leveAssignmentType-Blacksmith { background-position: -64px -32px }
.icon-leveAssignmentType-Botanist { background-position: 0px -64px }
.icon-leveAssignmentType-Carpenter { background-position: -32px -64px }
.icon-leveAssignmentType-Culinarian { background-position: -64px -64px }
.icon-leveAssignmentType-Fisher { background-position: -96px 0px }
.icon-leveAssignmentType-Goldsmith { background-position: -96px -32px }
.icon-leveAssignmentType-Immortal_Flames { background-position: -96px -64px }
.icon-leveAssignmentType-Leatherworker { background-position: 0px -96px }
.icon-leveAssignmentType-Miner { background-position: -32px -96px }
.icon-leveAssignmentType-Order_of_the_Twin_Adder { background-position: -64px -96px }
.icon-leveAssignmentType-The_Maelstrom { background-position: -96px -96px }
.icon-leveAssignmentType-Weaver { background-position: -128px 0px }