
.icon-hair {
	background-image: url(./hair.png);
	width: 96px;
	height: 96px;
}
.icon-hair-101_auraf { background-position: 0px 0px }
.icon-hair-101_auram { background-position: -96px 0px }
.icon-hair-101_elef { background-position: 0px -96px }
.icon-hair-101_elem { background-position: -96px -96px }
.icon-hair-101_highf { background-position: -192px 0px }
.icon-hair-101_highm { background-position: -192px -96px }
.icon-hair-101_lalaf { background-position: 0px -192px }
.icon-hair-101_lalam { background-position: -96px -192px }
.icon-hair-101_midf { background-position: -192px -192px }
.icon-hair-101_midm { background-position: -288px 0px }
.icon-hair-101_miqof { background-position: -288px -96px }
.icon-hair-101_miqom { background-position: -288px -192px }
.icon-hair-101_roef { background-position: 0px -288px }
.icon-hair-101_roem { background-position: -96px -288px }
.icon-hair-112_auraf { background-position: -192px -288px }
.icon-hair-112_auram { background-position: -288px -288px }
.icon-hair-112_elef { background-position: -384px 0px }
.icon-hair-112_elem { background-position: -384px -96px }
.icon-hair-112_highf { background-position: -384px -192px }
.icon-hair-112_highm { background-position: -384px -288px }
.icon-hair-112_lalaf { background-position: 0px -384px }
.icon-hair-112_lalam { background-position: -96px -384px }
.icon-hair-112_midf { background-position: -192px -384px }
.icon-hair-112_midm { background-position: -288px -384px }
.icon-hair-112_miqof { background-position: -384px -384px }
.icon-hair-112_miqom { background-position: -480px 0px }
.icon-hair-112_roef { background-position: -480px -96px }
.icon-hair-112_roem { background-position: -480px -192px }
.icon-hair-113_auraf { background-position: -480px -288px }
.icon-hair-113_auram { background-position: -480px -384px }
.icon-hair-113_elef { background-position: 0px -480px }
.icon-hair-113_elem { background-position: -96px -480px }
.icon-hair-113_highf { background-position: -192px -480px }
.icon-hair-113_highm { background-position: -288px -480px }
.icon-hair-113_lalaf { background-position: -384px -480px }
.icon-hair-113_lalam { background-position: -480px -480px }
.icon-hair-113_midf { background-position: -576px 0px }
.icon-hair-113_midm { background-position: -576px -96px }
.icon-hair-113_miqof { background-position: -576px -192px }
.icon-hair-113_miqom { background-position: -576px -288px }
.icon-hair-113_roef { background-position: -576px -384px }
.icon-hair-113_roem { background-position: -576px -480px }
.icon-hair-125_auraf { background-position: 0px -576px }
.icon-hair-125_auram { background-position: -96px -576px }
.icon-hair-125_elef { background-position: -192px -576px }
.icon-hair-125_elem { background-position: -288px -576px }
.icon-hair-125_highf { background-position: -384px -576px }
.icon-hair-125_highm { background-position: -480px -576px }
.icon-hair-125_lalaf { background-position: -576px -576px }
.icon-hair-125_lalam { background-position: -672px 0px }
.icon-hair-125_midf { background-position: -672px -96px }
.icon-hair-125_midm { background-position: -672px -192px }
.icon-hair-125_miqof { background-position: -672px -288px }
.icon-hair-125_miqom { background-position: -672px -384px }
.icon-hair-125_roef { background-position: -672px -480px }
.icon-hair-125_roem { background-position: -672px -576px }
.icon-hair-128_auraf { background-position: 0px -672px }
.icon-hair-128_auram { background-position: -96px -672px }
.icon-hair-128_elef { background-position: -192px -672px }
.icon-hair-128_elem { background-position: -288px -672px }
.icon-hair-128_highf { background-position: -384px -672px }
.icon-hair-128_highm { background-position: -480px -672px }
.icon-hair-128_lalaf { background-position: -576px -672px }
.icon-hair-128_lalam { background-position: -672px -672px }
.icon-hair-128_midf { background-position: -768px 0px }
.icon-hair-128_midm { background-position: -768px -96px }
.icon-hair-128_miqof { background-position: -768px -192px }
.icon-hair-128_miqom { background-position: -768px -288px }
.icon-hair-128_roef { background-position: -768px -384px }
.icon-hair-128_roem { background-position: -768px -480px }
.icon-hair-129_auraf { background-position: -768px -576px }
.icon-hair-129_auram { background-position: -768px -672px }
.icon-hair-129_elef { background-position: 0px -768px }
.icon-hair-129_elem { background-position: -96px -768px }
.icon-hair-129_highf { background-position: -192px -768px }
.icon-hair-129_highm { background-position: -288px -768px }
.icon-hair-129_lalaf { background-position: -384px -768px }
.icon-hair-129_lalam { background-position: -480px -768px }
.icon-hair-129_midf { background-position: -576px -768px }
.icon-hair-129_midm { background-position: -672px -768px }
.icon-hair-129_miqof { background-position: -768px -768px }
.icon-hair-129_miqom { background-position: -864px 0px }
.icon-hair-129_roef { background-position: -864px -96px }
.icon-hair-129_roem { background-position: -864px -192px }
.icon-hair-133_auraf { background-position: -864px -288px }
.icon-hair-133_auram { background-position: -864px -384px }
.icon-hair-133_elef { background-position: -864px -480px }
.icon-hair-133_elem { background-position: -864px -576px }
.icon-hair-133_highf { background-position: -864px -672px }
.icon-hair-133_highm { background-position: -864px -768px }
.icon-hair-133_lalaf { background-position: 0px -864px }
.icon-hair-133_lalam { background-position: -96px -864px }
.icon-hair-133_midf { background-position: -192px -864px }
.icon-hair-133_midm { background-position: -288px -864px }
.icon-hair-133_miqof { background-position: -384px -864px }
.icon-hair-133_miqom { background-position: -480px -864px }
.icon-hair-133_roef { background-position: -576px -864px }
.icon-hair-133_roem { background-position: -672px -864px }
.icon-hair-143_auraf { background-position: -768px -864px }
.icon-hair-143_auram { background-position: -864px -864px }
.icon-hair-143_elef { background-position: -960px 0px }
.icon-hair-143_elem { background-position: -960px -96px }
.icon-hair-143_highf { background-position: -960px -192px }
.icon-hair-143_highm { background-position: -960px -288px }
.icon-hair-143_lalaf { background-position: -960px -384px }
.icon-hair-143_lalam { background-position: -960px -480px }
.icon-hair-143_midf { background-position: -960px -576px }
.icon-hair-143_midm { background-position: -960px -672px }
.icon-hair-143_miqof { background-position: -960px -768px }
.icon-hair-143_miqom { background-position: -960px -864px }
.icon-hair-143_roef { background-position: 0px -960px }
.icon-hair-143_roem { background-position: -96px -960px }
.icon-hair-144_auraf { background-position: -192px -960px }
.icon-hair-144_auram { background-position: -288px -960px }
.icon-hair-144_elef { background-position: -384px -960px }
.icon-hair-144_elem { background-position: -480px -960px }
.icon-hair-144_highf { background-position: -576px -960px }
.icon-hair-144_highm { background-position: -672px -960px }
.icon-hair-144_lalaf { background-position: -768px -960px }
.icon-hair-144_lalam { background-position: -864px -960px }
.icon-hair-144_midf { background-position: -960px -960px }
.icon-hair-144_midm { background-position: -1056px 0px }
.icon-hair-144_miqof { background-position: -1056px -96px }
.icon-hair-144_miqom { background-position: -1056px -192px }
.icon-hair-144_roef { background-position: -1056px -288px }
.icon-hair-144_roem { background-position: -1056px -384px }
.icon-hair-145_auraf { background-position: -1056px -480px }
.icon-hair-145_auram { background-position: -1056px -576px }
.icon-hair-145_elef { background-position: -1056px -672px }
.icon-hair-145_elem { background-position: -1056px -768px }
.icon-hair-145_highf { background-position: -1056px -864px }
.icon-hair-145_highm { background-position: -1056px -960px }
.icon-hair-145_lalaf { background-position: 0px -1056px }
.icon-hair-145_lalam { background-position: -96px -1056px }
.icon-hair-145_midf { background-position: -192px -1056px }
.icon-hair-145_midm { background-position: -288px -1056px }
.icon-hair-145_miqof { background-position: -384px -1056px }
.icon-hair-145_miqom { background-position: -480px -1056px }
.icon-hair-145_roef { background-position: -576px -1056px }
.icon-hair-145_roem { background-position: -672px -1056px }
.icon-hair-147_auraf { background-position: -768px -1056px }
.icon-hair-147_auram { background-position: -864px -1056px }
.icon-hair-147_elef { background-position: -960px -1056px }
.icon-hair-147_elem { background-position: -1056px -1056px }
.icon-hair-147_highf { background-position: -1152px 0px }
.icon-hair-147_highm { background-position: -1152px -96px }
.icon-hair-147_lalaf { background-position: -1152px -192px }
.icon-hair-147_lalam { background-position: -1152px -288px }
.icon-hair-147_midf { background-position: -1152px -384px }
.icon-hair-147_midm { background-position: -1152px -480px }
.icon-hair-147_miqof { background-position: -1152px -576px }
.icon-hair-147_miqom { background-position: -1152px -672px }
.icon-hair-147_roef { background-position: -1152px -768px }
.icon-hair-147_roem { background-position: -1152px -864px }
.icon-hair-150_auraf { background-position: -1152px -960px }
.icon-hair-150_auram { background-position: -1152px -1056px }
.icon-hair-150_elef { background-position: 0px -1152px }
.icon-hair-150_elem { background-position: -96px -1152px }
.icon-hair-150_highf { background-position: -192px -1152px }
.icon-hair-150_highm { background-position: -288px -1152px }
.icon-hair-150_lalaf { background-position: -384px -1152px }
.icon-hair-150_lalam { background-position: -480px -1152px }
.icon-hair-150_midf { background-position: -576px -1152px }
.icon-hair-150_midm { background-position: -672px -1152px }
.icon-hair-150_miqof { background-position: -768px -1152px }
.icon-hair-150_miqom { background-position: -864px -1152px }
.icon-hair-150_roef { background-position: -960px -1152px }
.icon-hair-150_roem { background-position: -1056px -1152px }
.icon-hair-154_auraf { background-position: -1152px -1152px }
.icon-hair-154_auram { background-position: -1248px 0px }
.icon-hair-154_elef { background-position: -1248px -96px }
.icon-hair-154_elem { background-position: -1248px -192px }
.icon-hair-154_highf { background-position: -1248px -288px }
.icon-hair-154_highm { background-position: -1248px -384px }
.icon-hair-154_lalaf { background-position: -1248px -480px }
.icon-hair-154_lalam { background-position: -1248px -576px }
.icon-hair-154_midf { background-position: -1248px -672px }
.icon-hair-154_midm { background-position: -1248px -768px }
.icon-hair-154_miqof { background-position: -1248px -864px }
.icon-hair-154_miqom { background-position: -1248px -960px }
.icon-hair-154_roef { background-position: -1248px -1056px }
.icon-hair-154_roem { background-position: -1248px -1152px }
.icon-hair-155_auraf { background-position: 0px -1248px }
.icon-hair-155_auram { background-position: -96px -1248px }
.icon-hair-155_elef { background-position: -192px -1248px }
.icon-hair-155_elem { background-position: -288px -1248px }
.icon-hair-155_highf { background-position: -384px -1248px }
.icon-hair-155_highm { background-position: -480px -1248px }
.icon-hair-155_lalaf { background-position: -576px -1248px }
.icon-hair-155_lalam { background-position: -672px -1248px }
.icon-hair-155_midf { background-position: -768px -1248px }
.icon-hair-155_midm { background-position: -864px -1248px }
.icon-hair-155_miqof { background-position: -960px -1248px }
.icon-hair-155_miqom { background-position: -1056px -1248px }
.icon-hair-155_roef { background-position: -1152px -1248px }
.icon-hair-155_roem { background-position: -1248px -1248px }
.icon-hair-156_auraf { background-position: -1344px 0px }
.icon-hair-156_auram { background-position: -1344px -96px }
.icon-hair-156_elef { background-position: -1344px -192px }
.icon-hair-156_elem { background-position: -1344px -288px }
.icon-hair-156_highf { background-position: -1344px -384px }
.icon-hair-156_highm { background-position: -1344px -480px }
.icon-hair-156_lalaf { background-position: -1344px -576px }
.icon-hair-156_lalam { background-position: -1344px -672px }
.icon-hair-156_midf { background-position: -1344px -768px }
.icon-hair-156_midm { background-position: -1344px -864px }
.icon-hair-156_miqof { background-position: -1344px -960px }
.icon-hair-156_miqom { background-position: -1344px -1056px }
.icon-hair-156_roef { background-position: -1344px -1152px }
.icon-hair-156_roem { background-position: -1344px -1248px }
.icon-hair-157_auraf { background-position: 0px -1344px }
.icon-hair-157_auram { background-position: -96px -1344px }
.icon-hair-157_elef { background-position: -192px -1344px }
.icon-hair-157_elem { background-position: -288px -1344px }
.icon-hair-157_highf { background-position: -384px -1344px }
.icon-hair-157_highm { background-position: -480px -1344px }
.icon-hair-157_lalaf { background-position: -576px -1344px }
.icon-hair-157_lalam { background-position: -672px -1344px }
.icon-hair-157_midf { background-position: -768px -1344px }
.icon-hair-157_midm { background-position: -864px -1344px }
.icon-hair-157_miqof { background-position: -960px -1344px }
.icon-hair-157_miqom { background-position: -1056px -1344px }
.icon-hair-157_roef { background-position: -1152px -1344px }
.icon-hair-157_roem { background-position: -1248px -1344px }
.icon-hair-158_auraf { background-position: -1344px -1344px }
.icon-hair-158_auram { background-position: -1440px 0px }
.icon-hair-158_elef { background-position: -1440px -96px }
.icon-hair-158_elem { background-position: -1440px -192px }
.icon-hair-158_highf { background-position: -1440px -288px }
.icon-hair-158_highm { background-position: -1440px -384px }
.icon-hair-158_lalaf { background-position: -1440px -480px }
.icon-hair-158_lalam { background-position: -1440px -576px }
.icon-hair-158_midf { background-position: -1440px -672px }
.icon-hair-158_midm { background-position: -1440px -768px }
.icon-hair-158_miqof { background-position: -1440px -864px }
.icon-hair-158_miqom { background-position: -1440px -960px }
.icon-hair-158_roef { background-position: -1440px -1056px }
.icon-hair-158_roem { background-position: -1440px -1152px }
.icon-hair-160_auraf { background-position: -1440px -1248px }
.icon-hair-160_auram { background-position: -1440px -1344px }
.icon-hair-160_elef { background-position: 0px -1440px }
.icon-hair-160_elem { background-position: -96px -1440px }
.icon-hair-160_highf { background-position: -192px -1440px }
.icon-hair-160_highm { background-position: -288px -1440px }
.icon-hair-160_lalaf { background-position: -384px -1440px }
.icon-hair-160_lalam { background-position: -480px -1440px }
.icon-hair-160_midf { background-position: -576px -1440px }
.icon-hair-160_midm { background-position: -672px -1440px }
.icon-hair-160_miqof { background-position: -768px -1440px }
.icon-hair-160_miqom { background-position: -864px -1440px }
.icon-hair-160_roef { background-position: -960px -1440px }
.icon-hair-160_roem { background-position: -1056px -1440px }
.icon-hair-162_auraf { background-position: -1152px -1440px }
.icon-hair-162_auram { background-position: -1248px -1440px }
.icon-hair-162_elef { background-position: -1344px -1440px }
.icon-hair-162_elem { background-position: -1440px -1440px }
.icon-hair-162_highf { background-position: -1536px 0px }
.icon-hair-162_highm { background-position: -1536px -96px }
.icon-hair-162_lalaf { background-position: -1536px -192px }
.icon-hair-162_lalam { background-position: -1536px -288px }
.icon-hair-162_midf { background-position: -1536px -384px }
.icon-hair-162_midm { background-position: -1536px -480px }
.icon-hair-162_miqof { background-position: -1536px -576px }
.icon-hair-162_miqom { background-position: -1536px -672px }
.icon-hair-162_roef { background-position: -1536px -768px }
.icon-hair-162_roem { background-position: -1536px -864px }
.icon-hair-163_auraf { background-position: -1536px -960px }
.icon-hair-163_auram { background-position: -1536px -1056px }
.icon-hair-163_elef { background-position: -1536px -1152px }
.icon-hair-163_elem { background-position: -1536px -1248px }
.icon-hair-163_highf { background-position: -1536px -1344px }
.icon-hair-163_highm { background-position: -1536px -1440px }
.icon-hair-163_lalaf { background-position: 0px -1536px }
.icon-hair-163_lalam { background-position: -96px -1536px }
.icon-hair-163_midf { background-position: -192px -1536px }
.icon-hair-163_midm { background-position: -288px -1536px }
.icon-hair-163_miqof { background-position: -384px -1536px }
.icon-hair-163_miqom { background-position: -480px -1536px }
.icon-hair-163_roef { background-position: -576px -1536px }
.icon-hair-163_roem { background-position: -672px -1536px }
.icon-hair-164_auraf { background-position: -768px -1536px }
.icon-hair-164_auram { background-position: -864px -1536px }
.icon-hair-164_elef { background-position: -960px -1536px }
.icon-hair-164_elem { background-position: -1056px -1536px }
.icon-hair-164_highf { background-position: -1152px -1536px }
.icon-hair-164_highm { background-position: -1248px -1536px }
.icon-hair-164_lalaf { background-position: -1344px -1536px }
.icon-hair-164_lalam { background-position: -1440px -1536px }
.icon-hair-164_midf { background-position: -1536px -1536px }
.icon-hair-164_midm { background-position: -1632px 0px }
.icon-hair-164_miqof { background-position: -1632px -96px }
.icon-hair-164_miqom { background-position: -1632px -192px }
.icon-hair-164_roef { background-position: -1632px -288px }
.icon-hair-164_roem { background-position: -1632px -384px }
.icon-hair-165_auraf { background-position: -1632px -480px }
.icon-hair-165_auram { background-position: -1632px -576px }
.icon-hair-165_elef { background-position: -1632px -672px }
.icon-hair-165_elem { background-position: -1632px -768px }
.icon-hair-165_highf { background-position: -1632px -864px }
.icon-hair-165_highm { background-position: -1632px -960px }
.icon-hair-165_lalaf { background-position: -1632px -1056px }
.icon-hair-165_lalam { background-position: -1632px -1152px }
.icon-hair-165_midf { background-position: -1632px -1248px }
.icon-hair-165_midm { background-position: -1632px -1344px }
.icon-hair-165_miqof { background-position: -1632px -1440px }
.icon-hair-165_miqom { background-position: -1632px -1536px }
.icon-hair-165_roef { background-position: 0px -1632px }
.icon-hair-165_roem { background-position: -96px -1632px }
.icon-hair-166_auraf { background-position: -192px -1632px }
.icon-hair-166_auram { background-position: -288px -1632px }
.icon-hair-166_elef { background-position: -384px -1632px }
.icon-hair-166_elem { background-position: -480px -1632px }
.icon-hair-166_highf { background-position: -576px -1632px }
.icon-hair-166_highm { background-position: -672px -1632px }
.icon-hair-166_lalaf { background-position: -768px -1632px }
.icon-hair-166_lalam { background-position: -864px -1632px }
.icon-hair-166_midf { background-position: -960px -1632px }
.icon-hair-166_midm { background-position: -1056px -1632px }
.icon-hair-166_miqof { background-position: -1152px -1632px }
.icon-hair-166_miqom { background-position: -1248px -1632px }
.icon-hair-166_roef { background-position: -1344px -1632px }
.icon-hair-166_roem { background-position: -1440px -1632px }
.icon-hair-167_auraf { background-position: -1536px -1632px }
.icon-hair-167_auram { background-position: -1632px -1632px }
.icon-hair-167_elef { background-position: -1728px 0px }
.icon-hair-167_elem { background-position: -1728px -96px }
.icon-hair-167_highf { background-position: -1728px -192px }
.icon-hair-167_highm { background-position: -1728px -288px }
.icon-hair-167_lalaf { background-position: -1728px -384px }
.icon-hair-167_lalam { background-position: -1728px -480px }
.icon-hair-167_midf { background-position: -1728px -576px }
.icon-hair-167_midm { background-position: -1728px -672px }
.icon-hair-167_miqof { background-position: -1728px -768px }
.icon-hair-167_miqom { background-position: -1728px -864px }
.icon-hair-167_roef { background-position: -1728px -960px }
.icon-hair-167_roem { background-position: -1728px -1056px }
.icon-hair-168_auraf { background-position: -1728px -1152px }
.icon-hair-168_auram { background-position: -1728px -1248px }
.icon-hair-168_elef { background-position: -1728px -1344px }
.icon-hair-168_elem { background-position: -1728px -1440px }
.icon-hair-168_highf { background-position: -1728px -1536px }
.icon-hair-168_highm { background-position: -1728px -1632px }
.icon-hair-168_lalaf { background-position: 0px -1728px }
.icon-hair-168_lalam { background-position: -96px -1728px }
.icon-hair-168_midf { background-position: -192px -1728px }
.icon-hair-168_midm { background-position: -288px -1728px }
.icon-hair-168_miqof { background-position: -384px -1728px }
.icon-hair-168_miqom { background-position: -480px -1728px }
.icon-hair-168_roef { background-position: -576px -1728px }
.icon-hair-168_roem { background-position: -672px -1728px }
.icon-hair-169_auraf { background-position: -768px -1728px }
.icon-hair-169_auram { background-position: -864px -1728px }
.icon-hair-169_elef { background-position: -960px -1728px }
.icon-hair-169_elem { background-position: -1056px -1728px }
.icon-hair-169_highf { background-position: -1152px -1728px }
.icon-hair-169_highm { background-position: -1248px -1728px }
.icon-hair-169_lalaf { background-position: -1344px -1728px }
.icon-hair-169_lalam { background-position: -1440px -1728px }
.icon-hair-169_midf { background-position: -1536px -1728px }
.icon-hair-169_midm { background-position: -1632px -1728px }
.icon-hair-169_miqof { background-position: -1728px -1728px }
.icon-hair-169_miqom { background-position: -1824px 0px }
.icon-hair-169_roef { background-position: -1824px -96px }
.icon-hair-169_roem { background-position: -1824px -192px }
.icon-hair-170_auraf { background-position: -1824px -288px }
.icon-hair-170_auram { background-position: -1824px -384px }
.icon-hair-170_elef { background-position: -1824px -480px }
.icon-hair-170_elem { background-position: -1824px -576px }
.icon-hair-170_highf { background-position: -1824px -672px }
.icon-hair-170_highm { background-position: -1824px -768px }
.icon-hair-170_lalaf { background-position: -1824px -864px }
.icon-hair-170_lalam { background-position: -1824px -960px }
.icon-hair-170_midf { background-position: -1824px -1056px }
.icon-hair-170_midm { background-position: -1824px -1152px }
.icon-hair-170_miqof { background-position: -1824px -1248px }
.icon-hair-170_miqom { background-position: -1824px -1344px }
.icon-hair-170_roef { background-position: -1824px -1440px }
.icon-hair-170_roem { background-position: -1824px -1536px }
.icon-hair-171_auraf { background-position: -1824px -1632px }
.icon-hair-171_auram { background-position: -1824px -1728px }
.icon-hair-171_elef { background-position: 0px -1824px }
.icon-hair-171_elem { background-position: -96px -1824px }
.icon-hair-171_highf { background-position: -192px -1824px }
.icon-hair-171_highm { background-position: -288px -1824px }
.icon-hair-171_lalaf { background-position: -384px -1824px }
.icon-hair-171_lalam { background-position: -480px -1824px }
.icon-hair-171_midf { background-position: -576px -1824px }
.icon-hair-171_midm { background-position: -672px -1824px }
.icon-hair-171_miqof { background-position: -768px -1824px }
.icon-hair-171_miqom { background-position: -864px -1824px }
.icon-hair-171_roef { background-position: -960px -1824px }
.icon-hair-171_roem { background-position: -1056px -1824px }
.icon-hair-172_auraf { background-position: -1152px -1824px }
.icon-hair-172_auram { background-position: -1248px -1824px }
.icon-hair-172_elef { background-position: -1344px -1824px }
.icon-hair-172_elem { background-position: -1440px -1824px }
.icon-hair-172_highf { background-position: -1536px -1824px }
.icon-hair-172_highm { background-position: -1632px -1824px }
.icon-hair-172_lalaf { background-position: -1728px -1824px }
.icon-hair-172_lalam { background-position: -1824px -1824px }
.icon-hair-172_midf { background-position: -1920px 0px }
.icon-hair-172_midm { background-position: -1920px -96px }
.icon-hair-172_miqof { background-position: -1920px -192px }
.icon-hair-172_miqom { background-position: -1920px -288px }
.icon-hair-172_roef { background-position: -1920px -384px }
.icon-hair-172_roem { background-position: -1920px -480px }
.icon-hair-173_auraf { background-position: -1920px -576px }
.icon-hair-173_auram { background-position: -1920px -672px }
.icon-hair-173_elef { background-position: -1920px -768px }
.icon-hair-173_elem { background-position: -1920px -864px }
.icon-hair-173_highf { background-position: -1920px -960px }
.icon-hair-173_highm { background-position: -1920px -1056px }
.icon-hair-173_lalaf { background-position: -1920px -1152px }
.icon-hair-173_lalam { background-position: -1920px -1248px }
.icon-hair-173_midf { background-position: -1920px -1344px }
.icon-hair-173_midm { background-position: -1920px -1440px }
.icon-hair-173_miqof { background-position: -1920px -1536px }
.icon-hair-173_miqom { background-position: -1920px -1632px }
.icon-hair-173_roef { background-position: -1920px -1728px }
.icon-hair-173_roem { background-position: -1920px -1824px }
.icon-hair-174_auraf { background-position: 0px -1920px }
.icon-hair-174_auram { background-position: -96px -1920px }
.icon-hair-174_elef { background-position: -192px -1920px }
.icon-hair-174_elem { background-position: -288px -1920px }
.icon-hair-174_highf { background-position: -384px -1920px }
.icon-hair-174_highm { background-position: -480px -1920px }
.icon-hair-174_lalaf { background-position: -576px -1920px }
.icon-hair-174_lalam { background-position: -672px -1920px }
.icon-hair-174_midf { background-position: -768px -1920px }
.icon-hair-174_midm { background-position: -864px -1920px }
.icon-hair-174_miqof { background-position: -960px -1920px }
.icon-hair-174_miqom { background-position: -1056px -1920px }
.icon-hair-174_roef { background-position: -1152px -1920px }
.icon-hair-174_roem { background-position: -1248px -1920px }
.icon-hair-175_auraf { background-position: -1344px -1920px }
.icon-hair-175_auram { background-position: -1440px -1920px }
.icon-hair-175_elef { background-position: -1536px -1920px }
.icon-hair-175_elem { background-position: -1632px -1920px }
.icon-hair-175_highf { background-position: -1728px -1920px }
.icon-hair-175_highm { background-position: -1824px -1920px }
.icon-hair-175_lalaf { background-position: -1920px -1920px }
.icon-hair-175_lalam { background-position: -2016px 0px }
.icon-hair-175_midf { background-position: -2016px -96px }
.icon-hair-175_midm { background-position: -2016px -192px }
.icon-hair-175_miqof { background-position: -2016px -288px }
.icon-hair-175_miqom { background-position: -2016px -384px }
.icon-hair-175_roef { background-position: -2016px -480px }
.icon-hair-175_roem { background-position: -2016px -576px }
.icon-hair-176_auraf { background-position: -2016px -672px }
.icon-hair-176_auram { background-position: -2016px -768px }
.icon-hair-176_elef { background-position: -2016px -864px }
.icon-hair-176_elem { background-position: -2016px -960px }
.icon-hair-176_highf { background-position: -2016px -1056px }
.icon-hair-176_highm { background-position: -2016px -1152px }
.icon-hair-176_lalaf { background-position: -2016px -1248px }
.icon-hair-176_lalam { background-position: -2016px -1344px }
.icon-hair-176_midf { background-position: -2016px -1440px }
.icon-hair-176_midm { background-position: -2016px -1536px }
.icon-hair-176_miqof { background-position: -2016px -1632px }
.icon-hair-176_miqom { background-position: -2016px -1728px }
.icon-hair-176_roef { background-position: -2016px -1824px }
.icon-hair-176_roem { background-position: -2016px -1920px }
.icon-hair-177_auraf { background-position: 0px -2016px }
.icon-hair-177_auram { background-position: -96px -2016px }
.icon-hair-177_elef { background-position: -192px -2016px }
.icon-hair-177_elem { background-position: -288px -2016px }
.icon-hair-177_highf { background-position: -384px -2016px }
.icon-hair-177_highm { background-position: -480px -2016px }
.icon-hair-177_lalaf { background-position: -576px -2016px }
.icon-hair-177_lalam { background-position: -672px -2016px }
.icon-hair-177_midf { background-position: -768px -2016px }
.icon-hair-177_midm { background-position: -864px -2016px }
.icon-hair-177_miqof { background-position: -960px -2016px }
.icon-hair-177_miqom { background-position: -1056px -2016px }
.icon-hair-177_roef { background-position: -1152px -2016px }
.icon-hair-177_roem { background-position: -1248px -2016px }
.icon-hair-179_auraf { background-position: -1344px -2016px }
.icon-hair-179_auram { background-position: -1440px -2016px }
.icon-hair-179_elef { background-position: -1536px -2016px }
.icon-hair-179_elem { background-position: -1632px -2016px }
.icon-hair-179_highf { background-position: -1728px -2016px }
.icon-hair-179_highm { background-position: -1824px -2016px }
.icon-hair-179_lalaf { background-position: -1920px -2016px }
.icon-hair-179_lalam { background-position: -2016px -2016px }
.icon-hair-179_midf { background-position: -2112px 0px }
.icon-hair-179_midm { background-position: -2112px -96px }
.icon-hair-179_miqof { background-position: -2112px -192px }
.icon-hair-179_miqom { background-position: -2112px -288px }
.icon-hair-179_roef { background-position: -2112px -384px }
.icon-hair-179_roem { background-position: -2112px -480px }
.icon-hair-180_auraf { background-position: -2112px -576px }
.icon-hair-180_auram { background-position: -2112px -672px }
.icon-hair-180_elef { background-position: -2112px -768px }
.icon-hair-180_elem { background-position: -2112px -864px }
.icon-hair-180_highf { background-position: -2112px -960px }
.icon-hair-180_highm { background-position: -2112px -1056px }
.icon-hair-180_lalaf { background-position: -2112px -1152px }
.icon-hair-180_lalam { background-position: -2112px -1248px }
.icon-hair-180_midf { background-position: -2112px -1344px }
.icon-hair-180_midm { background-position: -2112px -1440px }
.icon-hair-180_miqof { background-position: -2112px -1536px }
.icon-hair-180_miqom { background-position: -2112px -1632px }
.icon-hair-180_roef { background-position: -2112px -1728px }
.icon-hair-180_roem { background-position: -2112px -1824px }
.icon-hair-181_auraf { background-position: -2112px -1920px }
.icon-hair-181_auram { background-position: -2112px -2016px }
.icon-hair-181_elef { background-position: 0px -2112px }
.icon-hair-181_elem { background-position: -96px -2112px }
.icon-hair-181_highf { background-position: -192px -2112px }
.icon-hair-181_highm { background-position: -288px -2112px }
.icon-hair-181_lalaf { background-position: -384px -2112px }
.icon-hair-181_lalam { background-position: -480px -2112px }
.icon-hair-181_midf { background-position: -576px -2112px }
.icon-hair-181_midm { background-position: -672px -2112px }
.icon-hair-181_miqof { background-position: -768px -2112px }
.icon-hair-181_miqom { background-position: -864px -2112px }
.icon-hair-181_roef { background-position: -960px -2112px }
.icon-hair-181_roem { background-position: -1056px -2112px }
.icon-hair-182_auraf { background-position: -1152px -2112px }
.icon-hair-182_auram { background-position: -1248px -2112px }
.icon-hair-182_elef { background-position: -1344px -2112px }
.icon-hair-182_elem { background-position: -1440px -2112px }
.icon-hair-182_highf { background-position: -1536px -2112px }
.icon-hair-182_highm { background-position: -1632px -2112px }
.icon-hair-182_lalaf { background-position: -1728px -2112px }
.icon-hair-182_lalam { background-position: -1824px -2112px }
.icon-hair-182_midf { background-position: -1920px -2112px }
.icon-hair-182_midm { background-position: -2016px -2112px }
.icon-hair-182_miqof { background-position: -2112px -2112px }
.icon-hair-182_miqom { background-position: -2208px 0px }
.icon-hair-182_roef { background-position: -2208px -96px }
.icon-hair-182_roem { background-position: -2208px -192px }
.icon-hair-183_auraf { background-position: -2208px -288px }
.icon-hair-183_auram { background-position: -2208px -384px }
.icon-hair-183_elef { background-position: -2208px -480px }
.icon-hair-183_elem { background-position: -2208px -576px }
.icon-hair-183_highf { background-position: -2208px -672px }
.icon-hair-183_highm { background-position: -2208px -768px }
.icon-hair-183_lalaf { background-position: -2208px -864px }
.icon-hair-183_lalam { background-position: -2208px -960px }
.icon-hair-183_midf { background-position: -2208px -1056px }
.icon-hair-183_midm { background-position: -2208px -1152px }
.icon-hair-183_miqof { background-position: -2208px -1248px }
.icon-hair-183_miqom { background-position: -2208px -1344px }
.icon-hair-183_roef { background-position: -2208px -1440px }
.icon-hair-183_roem { background-position: -2208px -1536px }
.icon-hair-184_auraf { background-position: -2208px -1632px }
.icon-hair-184_auram { background-position: -2208px -1728px }
.icon-hair-184_elef { background-position: -2208px -1824px }
.icon-hair-184_elem { background-position: -2208px -1920px }
.icon-hair-184_highf { background-position: -2208px -2016px }
.icon-hair-184_highm { background-position: -2208px -2112px }
.icon-hair-184_lalaf { background-position: 0px -2208px }
.icon-hair-184_lalam { background-position: -96px -2208px }
.icon-hair-184_midf { background-position: -192px -2208px }
.icon-hair-184_midm { background-position: -288px -2208px }
.icon-hair-184_miqof { background-position: -384px -2208px }
.icon-hair-184_miqom { background-position: -480px -2208px }
.icon-hair-184_roef { background-position: -576px -2208px }
.icon-hair-184_roem { background-position: -672px -2208px }
.icon-hair-61_auraf { background-position: -768px -2208px }
.icon-hair-61_auram { background-position: -864px -2208px }
.icon-hair-61_elef { background-position: -960px -2208px }
.icon-hair-61_elem { background-position: -1056px -2208px }
.icon-hair-61_highf { background-position: -1152px -2208px }
.icon-hair-61_highm { background-position: -1248px -2208px }
.icon-hair-61_lalaf { background-position: -1344px -2208px }
.icon-hair-61_lalam { background-position: -1440px -2208px }
.icon-hair-61_midf { background-position: -1536px -2208px }
.icon-hair-61_midm { background-position: -1632px -2208px }
.icon-hair-61_miqof { background-position: -1728px -2208px }
.icon-hair-61_miqom { background-position: -1824px -2208px }
.icon-hair-61_roef { background-position: -1920px -2208px }
.icon-hair-61_roem { background-position: -2016px -2208px }