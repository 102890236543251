
.icon-moogletomes {
	background-image: url(./moogletomes.png);
	width: 40px;
	height: 40px;
}
.icon-moogletomes-13364 { background-position: 0px 0px }
.icon-moogletomes-13365 { background-position: -40px 0px }
.icon-moogletomes-16784 { background-position: 0px -40px }
.icon-moogletomes-18284 { background-position: -40px -40px }
.icon-moogletomes-18286 { background-position: -80px 0px }
.icon-moogletomes-18290 { background-position: -80px -40px }
.icon-moogletomes-18292 { background-position: 0px -80px }
.icon-moogletomes-18296 { background-position: -40px -80px }
.icon-moogletomes-18298 { background-position: -80px -80px }
.icon-moogletomes-18302 { background-position: -120px 0px }
.icon-moogletomes-18304 { background-position: -120px -40px }
.icon-moogletomes-18308 { background-position: -120px -80px }
.icon-moogletomes-18310 { background-position: 0px -120px }
.icon-moogletomes-18314 { background-position: -40px -120px }
.icon-moogletomes-18316 { background-position: -80px -120px }
.icon-moogletomes-18320 { background-position: -120px -120px }
.icon-moogletomes-18322 { background-position: -160px 0px }
.icon-moogletomes-20556 { background-position: -160px -40px }
.icon-moogletomes-20586 { background-position: -160px -80px }
.icon-moogletomes-20587 { background-position: -160px -120px }
.icon-moogletomes-20588 { background-position: 0px -160px }
.icon-moogletomes-23020 { background-position: -40px -160px }
.icon-moogletomes-23051 { background-position: -80px -160px }
.icon-moogletomes-23325 { background-position: -120px -160px }
.icon-moogletomes-23895 { background-position: -160px -160px }
.icon-moogletomes-23984 { background-position: -200px 0px }
.icon-moogletomes-24224 { background-position: -200px -40px }
.icon-moogletomes-24226 { background-position: -200px -80px }
.icon-moogletomes-24227 { background-position: -200px -120px }
.icon-moogletomes-24228 { background-position: -200px -160px }
.icon-moogletomes-24633 { background-position: 0px -200px }
.icon-moogletomes-24794 { background-position: -40px -200px }
.icon-moogletomes-25005 { background-position: -80px -200px }
.icon-moogletomes-26776 { background-position: -120px -200px }
.icon-moogletomes-26783 { background-position: -160px -200px }
.icon-moogletomes-26790 { background-position: -200px -200px }
.icon-moogletomes-28616 { background-position: -240px 0px }
.icon-moogletomes-30248 { background-position: -240px -40px }
.icon-moogletomes-31349 { background-position: -240px -80px }
.icon-moogletomes-31403 { background-position: -240px -120px }
.icon-moogletomes-32835 { background-position: -240px -160px }
.icon-moogletomes-33144 { background-position: -240px -200px }
.icon-moogletomes-33284 { background-position: 0px -240px }
.icon-moogletomes-33285 { background-position: -40px -240px }
.icon-moogletomes-33295 { background-position: -80px -240px }
.icon-moogletomes-33296 { background-position: -120px -240px }
.icon-moogletomes-33328 { background-position: -160px -240px }
.icon-moogletomes-33650 { background-position: -200px -240px }
.icon-moogletomes-35563 { background-position: -240px -240px }
.icon-moogletomes-39593 { background-position: -280px 0px }
.icon-moogletomes-41503 { background-position: -280px -40px }
.icon-moogletomes-6984 { background-position: -280px -80px }
.icon-moogletomes-6991 { background-position: -280px -120px }
.icon-moogletomes-6992 { background-position: -280px -160px }
.icon-moogletomes-7894 { background-position: -280px -200px }