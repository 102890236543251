
.icon-minion {
	background-image: url(./minion.png);
	width: 40px;
	height: 40px;
}
.icon-minion-1 { background-position: 0px 0px }
.icon-minion-10 { background-position: -40px 0px }
.icon-minion-100 { background-position: 0px -40px }
.icon-minion-101 { background-position: -40px -40px }
.icon-minion-102 { background-position: -80px 0px }
.icon-minion-103 { background-position: -80px -40px }
.icon-minion-104 { background-position: 0px -80px }
.icon-minion-105 { background-position: -40px -80px }
.icon-minion-106 { background-position: -80px -80px }
.icon-minion-107 { background-position: -120px 0px }
.icon-minion-108 { background-position: -120px -40px }
.icon-minion-109 { background-position: -120px -80px }
.icon-minion-11 { background-position: 0px -120px }
.icon-minion-110 { background-position: -40px -120px }
.icon-minion-111 { background-position: -80px -120px }
.icon-minion-112 { background-position: -120px -120px }
.icon-minion-113 { background-position: -160px 0px }
.icon-minion-114 { background-position: -160px -40px }
.icon-minion-115 { background-position: -160px -80px }
.icon-minion-116 { background-position: -160px -120px }
.icon-minion-117 { background-position: 0px -160px }
.icon-minion-118 { background-position: -40px -160px }
.icon-minion-119 { background-position: -80px -160px }
.icon-minion-12 { background-position: -120px -160px }
.icon-minion-121 { background-position: -160px -160px }
.icon-minion-122 { background-position: -200px 0px }
.icon-minion-123 { background-position: -200px -40px }
.icon-minion-124 { background-position: -200px -80px }
.icon-minion-125 { background-position: -200px -120px }
.icon-minion-126 { background-position: -200px -160px }
.icon-minion-127 { background-position: 0px -200px }
.icon-minion-128 { background-position: -40px -200px }
.icon-minion-129 { background-position: -80px -200px }
.icon-minion-13 { background-position: -120px -200px }
.icon-minion-130 { background-position: -160px -200px }
.icon-minion-131 { background-position: -200px -200px }
.icon-minion-132 { background-position: -240px 0px }
.icon-minion-133 { background-position: -240px -40px }
.icon-minion-134 { background-position: -240px -80px }
.icon-minion-135 { background-position: -240px -120px }
.icon-minion-136 { background-position: -240px -160px }
.icon-minion-137 { background-position: -240px -200px }
.icon-minion-138 { background-position: 0px -240px }
.icon-minion-139 { background-position: -40px -240px }
.icon-minion-14 { background-position: -80px -240px }
.icon-minion-140 { background-position: -120px -240px }
.icon-minion-141 { background-position: -160px -240px }
.icon-minion-142 { background-position: -200px -240px }
.icon-minion-143 { background-position: -240px -240px }
.icon-minion-144 { background-position: -280px 0px }
.icon-minion-145 { background-position: -280px -40px }
.icon-minion-146 { background-position: -280px -80px }
.icon-minion-147 { background-position: -280px -120px }
.icon-minion-148 { background-position: -280px -160px }
.icon-minion-149 { background-position: -280px -200px }
.icon-minion-15 { background-position: -280px -240px }
.icon-minion-150 { background-position: 0px -280px }
.icon-minion-151 { background-position: -40px -280px }
.icon-minion-152 { background-position: -80px -280px }
.icon-minion-154 { background-position: -120px -280px }
.icon-minion-155 { background-position: -160px -280px }
.icon-minion-156 { background-position: -200px -280px }
.icon-minion-157 { background-position: -240px -280px }
.icon-minion-158 { background-position: -280px -280px }
.icon-minion-159 { background-position: -320px 0px }
.icon-minion-16 { background-position: -320px -40px }
.icon-minion-160 { background-position: -320px -80px }
.icon-minion-161 { background-position: -320px -120px }
.icon-minion-162 { background-position: -320px -160px }
.icon-minion-163 { background-position: -320px -200px }
.icon-minion-164 { background-position: -320px -240px }
.icon-minion-165 { background-position: -320px -280px }
.icon-minion-166 { background-position: 0px -320px }
.icon-minion-167 { background-position: -40px -320px }
.icon-minion-168 { background-position: -80px -320px }
.icon-minion-169 { background-position: -120px -320px }
.icon-minion-17 { background-position: -160px -320px }
.icon-minion-170 { background-position: -200px -320px }
.icon-minion-171 { background-position: -240px -320px }
.icon-minion-172 { background-position: -280px -320px }
.icon-minion-173 { background-position: -320px -320px }
.icon-minion-174 { background-position: -360px 0px }
.icon-minion-175 { background-position: -360px -40px }
.icon-minion-176 { background-position: -360px -80px }
.icon-minion-177 { background-position: -360px -120px }
.icon-minion-178 { background-position: -360px -160px }
.icon-minion-179 { background-position: -360px -200px }
.icon-minion-18 { background-position: -360px -240px }
.icon-minion-180 { background-position: -360px -280px }
.icon-minion-181 { background-position: -360px -320px }
.icon-minion-182 { background-position: 0px -360px }
.icon-minion-183 { background-position: -40px -360px }
.icon-minion-184 { background-position: -80px -360px }
.icon-minion-185 { background-position: -120px -360px }
.icon-minion-186 { background-position: -160px -360px }
.icon-minion-187 { background-position: -200px -360px }
.icon-minion-188 { background-position: -240px -360px }
.icon-minion-189 { background-position: -280px -360px }
.icon-minion-19 { background-position: -320px -360px }
.icon-minion-190 { background-position: -360px -360px }
.icon-minion-191 { background-position: -400px 0px }
.icon-minion-192 { background-position: -400px -40px }
.icon-minion-193 { background-position: -400px -80px }
.icon-minion-194 { background-position: -400px -120px }
.icon-minion-195 { background-position: -400px -160px }
.icon-minion-196 { background-position: -400px -200px }
.icon-minion-197 { background-position: -400px -240px }
.icon-minion-198 { background-position: -400px -280px }
.icon-minion-199 { background-position: -400px -320px }
.icon-minion-2 { background-position: -400px -360px }
.icon-minion-20 { background-position: 0px -400px }
.icon-minion-200 { background-position: -40px -400px }
.icon-minion-201 { background-position: -80px -400px }
.icon-minion-202 { background-position: -120px -400px }
.icon-minion-203 { background-position: -160px -400px }
.icon-minion-204 { background-position: -200px -400px }
.icon-minion-205 { background-position: -240px -400px }
.icon-minion-206 { background-position: -280px -400px }
.icon-minion-207 { background-position: -320px -400px }
.icon-minion-208 { background-position: -360px -400px }
.icon-minion-209 { background-position: -400px -400px }
.icon-minion-21 { background-position: -440px 0px }
.icon-minion-210 { background-position: -440px -40px }
.icon-minion-211 { background-position: -440px -80px }
.icon-minion-212 { background-position: -440px -120px }
.icon-minion-214 { background-position: -440px -160px }
.icon-minion-215 { background-position: -440px -200px }
.icon-minion-216 { background-position: -440px -240px }
.icon-minion-217 { background-position: -440px -280px }
.icon-minion-218 { background-position: -440px -320px }
.icon-minion-219 { background-position: -440px -360px }
.icon-minion-22 { background-position: -440px -400px }
.icon-minion-220 { background-position: 0px -440px }
.icon-minion-221 { background-position: -40px -440px }
.icon-minion-222 { background-position: -80px -440px }
.icon-minion-224 { background-position: -120px -440px }
.icon-minion-225 { background-position: -160px -440px }
.icon-minion-226 { background-position: -200px -440px }
.icon-minion-227 { background-position: -240px -440px }
.icon-minion-228 { background-position: -280px -440px }
.icon-minion-229 { background-position: -320px -440px }
.icon-minion-23 { background-position: -360px -440px }
.icon-minion-230 { background-position: -400px -440px }
.icon-minion-231 { background-position: -440px -440px }
.icon-minion-232 { background-position: -480px 0px }
.icon-minion-233 { background-position: -480px -40px }
.icon-minion-234 { background-position: -480px -80px }
.icon-minion-235 { background-position: -480px -120px }
.icon-minion-236 { background-position: -480px -160px }
.icon-minion-237 { background-position: -480px -200px }
.icon-minion-238 { background-position: -480px -240px }
.icon-minion-239 { background-position: -480px -280px }
.icon-minion-24 { background-position: -480px -320px }
.icon-minion-240 { background-position: -480px -360px }
.icon-minion-241 { background-position: -480px -400px }
.icon-minion-242 { background-position: -480px -440px }
.icon-minion-243 { background-position: 0px -480px }
.icon-minion-244 { background-position: -40px -480px }
.icon-minion-245 { background-position: -80px -480px }
.icon-minion-246 { background-position: -120px -480px }
.icon-minion-247 { background-position: -160px -480px }
.icon-minion-248 { background-position: -200px -480px }
.icon-minion-249 { background-position: -240px -480px }
.icon-minion-25 { background-position: -280px -480px }
.icon-minion-250 { background-position: -320px -480px }
.icon-minion-251 { background-position: -360px -480px }
.icon-minion-252 { background-position: -400px -480px }
.icon-minion-253 { background-position: -440px -480px }
.icon-minion-254 { background-position: -480px -480px }
.icon-minion-255 { background-position: -520px 0px }
.icon-minion-256 { background-position: -520px -40px }
.icon-minion-257 { background-position: -520px -80px }
.icon-minion-258 { background-position: -520px -120px }
.icon-minion-259 { background-position: -520px -160px }
.icon-minion-26 { background-position: -520px -200px }
.icon-minion-260 { background-position: -520px -240px }
.icon-minion-261 { background-position: -520px -280px }
.icon-minion-262 { background-position: -520px -320px }
.icon-minion-263 { background-position: -520px -360px }
.icon-minion-264 { background-position: -520px -400px }
.icon-minion-265 { background-position: -520px -440px }
.icon-minion-266 { background-position: -520px -480px }
.icon-minion-267 { background-position: 0px -520px }
.icon-minion-268 { background-position: -40px -520px }
.icon-minion-269 { background-position: -80px -520px }
.icon-minion-27 { background-position: -120px -520px }
.icon-minion-270 { background-position: -160px -520px }
.icon-minion-271 { background-position: -200px -520px }
.icon-minion-272 { background-position: -240px -520px }
.icon-minion-273 { background-position: -280px -520px }
.icon-minion-274 { background-position: -320px -520px }
.icon-minion-275 { background-position: -360px -520px }
.icon-minion-276 { background-position: -400px -520px }
.icon-minion-277 { background-position: -440px -520px }
.icon-minion-278 { background-position: -480px -520px }
.icon-minion-279 { background-position: -520px -520px }
.icon-minion-28 { background-position: -560px 0px }
.icon-minion-280 { background-position: -560px -40px }
.icon-minion-281 { background-position: -560px -80px }
.icon-minion-282 { background-position: -560px -120px }
.icon-minion-283 { background-position: -560px -160px }
.icon-minion-284 { background-position: -560px -200px }
.icon-minion-285 { background-position: -560px -240px }
.icon-minion-286 { background-position: -560px -280px }
.icon-minion-287 { background-position: -560px -320px }
.icon-minion-288 { background-position: -560px -360px }
.icon-minion-289 { background-position: -560px -400px }
.icon-minion-29 { background-position: -560px -440px }
.icon-minion-290 { background-position: -560px -480px }
.icon-minion-291 { background-position: -560px -520px }
.icon-minion-292 { background-position: 0px -560px }
.icon-minion-293 { background-position: -40px -560px }
.icon-minion-294 { background-position: -80px -560px }
.icon-minion-295 { background-position: -120px -560px }
.icon-minion-296 { background-position: -160px -560px }
.icon-minion-297 { background-position: -200px -560px }
.icon-minion-298 { background-position: -240px -560px }
.icon-minion-299 { background-position: -280px -560px }
.icon-minion-3 { background-position: -320px -560px }
.icon-minion-30 { background-position: -360px -560px }
.icon-minion-300 { background-position: -400px -560px }
.icon-minion-301 { background-position: -440px -560px }
.icon-minion-302 { background-position: -480px -560px }
.icon-minion-303 { background-position: -520px -560px }
.icon-minion-304 { background-position: -560px -560px }
.icon-minion-305 { background-position: -600px 0px }
.icon-minion-306 { background-position: -600px -40px }
.icon-minion-307 { background-position: -600px -80px }
.icon-minion-308 { background-position: -600px -120px }
.icon-minion-309 { background-position: -600px -160px }
.icon-minion-31 { background-position: -600px -200px }
.icon-minion-310 { background-position: -600px -240px }
.icon-minion-311 { background-position: -600px -280px }
.icon-minion-312 { background-position: -600px -320px }
.icon-minion-313 { background-position: -600px -360px }
.icon-minion-314 { background-position: -600px -400px }
.icon-minion-315 { background-position: -600px -440px }
.icon-minion-316 { background-position: -600px -480px }
.icon-minion-317 { background-position: -600px -520px }
.icon-minion-318 { background-position: -600px -560px }
.icon-minion-319 { background-position: 0px -600px }
.icon-minion-32 { background-position: -40px -600px }
.icon-minion-320 { background-position: -80px -600px }
.icon-minion-321 { background-position: -120px -600px }
.icon-minion-322 { background-position: -160px -600px }
.icon-minion-323 { background-position: -200px -600px }
.icon-minion-324 { background-position: -240px -600px }
.icon-minion-325 { background-position: -280px -600px }
.icon-minion-326 { background-position: -320px -600px }
.icon-minion-327 { background-position: -360px -600px }
.icon-minion-328 { background-position: -400px -600px }
.icon-minion-329 { background-position: -440px -600px }
.icon-minion-33 { background-position: -480px -600px }
.icon-minion-330 { background-position: -520px -600px }
.icon-minion-331 { background-position: -560px -600px }
.icon-minion-332 { background-position: -600px -600px }
.icon-minion-333 { background-position: -640px 0px }
.icon-minion-334 { background-position: -640px -40px }
.icon-minion-335 { background-position: -640px -80px }
.icon-minion-336 { background-position: -640px -120px }
.icon-minion-337 { background-position: -640px -160px }
.icon-minion-338 { background-position: -640px -200px }
.icon-minion-339 { background-position: -640px -240px }
.icon-minion-34 { background-position: -640px -280px }
.icon-minion-340 { background-position: -640px -320px }
.icon-minion-341 { background-position: -640px -360px }
.icon-minion-342 { background-position: -640px -400px }
.icon-minion-343 { background-position: -640px -440px }
.icon-minion-344 { background-position: -640px -480px }
.icon-minion-345 { background-position: -640px -520px }
.icon-minion-346 { background-position: -640px -560px }
.icon-minion-347 { background-position: -640px -600px }
.icon-minion-348 { background-position: 0px -640px }
.icon-minion-349 { background-position: -40px -640px }
.icon-minion-35 { background-position: -80px -640px }
.icon-minion-350 { background-position: -120px -640px }
.icon-minion-351 { background-position: -160px -640px }
.icon-minion-352 { background-position: -200px -640px }
.icon-minion-353 { background-position: -240px -640px }
.icon-minion-354 { background-position: -280px -640px }
.icon-minion-355 { background-position: -320px -640px }
.icon-minion-356 { background-position: -360px -640px }
.icon-minion-357 { background-position: -400px -640px }
.icon-minion-358 { background-position: -440px -640px }
.icon-minion-359 { background-position: -480px -640px }
.icon-minion-36 { background-position: -520px -640px }
.icon-minion-360 { background-position: -560px -640px }
.icon-minion-361 { background-position: -600px -640px }
.icon-minion-362 { background-position: -640px -640px }
.icon-minion-363 { background-position: -680px 0px }
.icon-minion-364 { background-position: -680px -40px }
.icon-minion-365 { background-position: -680px -80px }
.icon-minion-366 { background-position: -680px -120px }
.icon-minion-367 { background-position: -680px -160px }
.icon-minion-368 { background-position: -680px -200px }
.icon-minion-369 { background-position: -680px -240px }
.icon-minion-37 { background-position: -680px -280px }
.icon-minion-370 { background-position: -680px -320px }
.icon-minion-371 { background-position: -680px -360px }
.icon-minion-372 { background-position: -680px -400px }
.icon-minion-373 { background-position: -680px -440px }
.icon-minion-374 { background-position: -680px -480px }
.icon-minion-375 { background-position: -680px -520px }
.icon-minion-376 { background-position: -680px -560px }
.icon-minion-377 { background-position: -680px -600px }
.icon-minion-378 { background-position: -680px -640px }
.icon-minion-379 { background-position: 0px -680px }
.icon-minion-38 { background-position: -40px -680px }
.icon-minion-380 { background-position: -80px -680px }
.icon-minion-381 { background-position: -120px -680px }
.icon-minion-382 { background-position: -160px -680px }
.icon-minion-383 { background-position: -200px -680px }
.icon-minion-384 { background-position: -240px -680px }
.icon-minion-385 { background-position: -280px -680px }
.icon-minion-386 { background-position: -320px -680px }
.icon-minion-387 { background-position: -360px -680px }
.icon-minion-388 { background-position: -400px -680px }
.icon-minion-389 { background-position: -440px -680px }
.icon-minion-39 { background-position: -480px -680px }
.icon-minion-390 { background-position: -520px -680px }
.icon-minion-391 { background-position: -560px -680px }
.icon-minion-392 { background-position: -600px -680px }
.icon-minion-393 { background-position: -640px -680px }
.icon-minion-394 { background-position: -680px -680px }
.icon-minion-395 { background-position: -720px 0px }
.icon-minion-396 { background-position: -720px -40px }
.icon-minion-397 { background-position: -720px -80px }
.icon-minion-398 { background-position: -720px -120px }
.icon-minion-399 { background-position: -720px -160px }
.icon-minion-4 { background-position: -720px -200px }
.icon-minion-40 { background-position: -720px -240px }
.icon-minion-400 { background-position: -720px -280px }
.icon-minion-401 { background-position: -720px -320px }
.icon-minion-402 { background-position: -720px -360px }
.icon-minion-403 { background-position: -720px -400px }
.icon-minion-404 { background-position: -720px -440px }
.icon-minion-405 { background-position: -720px -480px }
.icon-minion-406 { background-position: -720px -520px }
.icon-minion-407 { background-position: -720px -560px }
.icon-minion-408 { background-position: -720px -600px }
.icon-minion-409 { background-position: -720px -640px }
.icon-minion-41 { background-position: -720px -680px }
.icon-minion-410 { background-position: 0px -720px }
.icon-minion-411 { background-position: -40px -720px }
.icon-minion-412 { background-position: -80px -720px }
.icon-minion-413 { background-position: -120px -720px }
.icon-minion-414 { background-position: -160px -720px }
.icon-minion-415 { background-position: -200px -720px }
.icon-minion-416 { background-position: -240px -720px }
.icon-minion-417 { background-position: -280px -720px }
.icon-minion-418 { background-position: -320px -720px }
.icon-minion-419 { background-position: -360px -720px }
.icon-minion-42 { background-position: -400px -720px }
.icon-minion-420 { background-position: -440px -720px }
.icon-minion-421 { background-position: -480px -720px }
.icon-minion-422 { background-position: -520px -720px }
.icon-minion-423 { background-position: -560px -720px }
.icon-minion-424 { background-position: -600px -720px }
.icon-minion-425 { background-position: -640px -720px }
.icon-minion-426 { background-position: -680px -720px }
.icon-minion-427 { background-position: -720px -720px }
.icon-minion-428 { background-position: -760px 0px }
.icon-minion-429 { background-position: -760px -40px }
.icon-minion-43 { background-position: -760px -80px }
.icon-minion-430 { background-position: -760px -120px }
.icon-minion-431 { background-position: -760px -160px }
.icon-minion-432 { background-position: -760px -200px }
.icon-minion-433 { background-position: -760px -240px }
.icon-minion-434 { background-position: -760px -280px }
.icon-minion-435 { background-position: -760px -320px }
.icon-minion-436 { background-position: -760px -360px }
.icon-minion-437 { background-position: -760px -400px }
.icon-minion-438 { background-position: -760px -440px }
.icon-minion-439 { background-position: -760px -480px }
.icon-minion-44 { background-position: -760px -520px }
.icon-minion-440 { background-position: -760px -560px }
.icon-minion-441 { background-position: -760px -600px }
.icon-minion-442 { background-position: -760px -640px }
.icon-minion-443 { background-position: -760px -680px }
.icon-minion-444 { background-position: -760px -720px }
.icon-minion-445 { background-position: 0px -760px }
.icon-minion-446 { background-position: -40px -760px }
.icon-minion-447 { background-position: -80px -760px }
.icon-minion-448 { background-position: -120px -760px }
.icon-minion-449 { background-position: -160px -760px }
.icon-minion-45 { background-position: -200px -760px }
.icon-minion-450 { background-position: -240px -760px }
.icon-minion-451 { background-position: -280px -760px }
.icon-minion-452 { background-position: -320px -760px }
.icon-minion-453 { background-position: -360px -760px }
.icon-minion-454 { background-position: -400px -760px }
.icon-minion-455 { background-position: -440px -760px }
.icon-minion-456 { background-position: -480px -760px }
.icon-minion-457 { background-position: -520px -760px }
.icon-minion-458 { background-position: -560px -760px }
.icon-minion-459 { background-position: -600px -760px }
.icon-minion-46 { background-position: -640px -760px }
.icon-minion-460 { background-position: -680px -760px }
.icon-minion-461 { background-position: -720px -760px }
.icon-minion-462 { background-position: -760px -760px }
.icon-minion-463 { background-position: -800px 0px }
.icon-minion-464 { background-position: -800px -40px }
.icon-minion-465 { background-position: -800px -80px }
.icon-minion-466 { background-position: -800px -120px }
.icon-minion-467 { background-position: -800px -160px }
.icon-minion-468 { background-position: -800px -200px }
.icon-minion-469 { background-position: -800px -240px }
.icon-minion-47 { background-position: -800px -280px }
.icon-minion-470 { background-position: -800px -320px }
.icon-minion-471 { background-position: -800px -360px }
.icon-minion-472 { background-position: -800px -400px }
.icon-minion-473 { background-position: -800px -440px }
.icon-minion-474 { background-position: -800px -480px }
.icon-minion-475 { background-position: -800px -520px }
.icon-minion-476 { background-position: -800px -560px }
.icon-minion-477 { background-position: -800px -600px }
.icon-minion-478 { background-position: -800px -640px }
.icon-minion-479 { background-position: -800px -680px }
.icon-minion-48 { background-position: -800px -720px }
.icon-minion-480 { background-position: -800px -760px }
.icon-minion-481 { background-position: 0px -800px }
.icon-minion-482 { background-position: -40px -800px }
.icon-minion-483 { background-position: -80px -800px }
.icon-minion-484 { background-position: -120px -800px }
.icon-minion-485 { background-position: -160px -800px }
.icon-minion-486 { background-position: -200px -800px }
.icon-minion-487 { background-position: -240px -800px }
.icon-minion-488 { background-position: -280px -800px }
.icon-minion-489 { background-position: -320px -800px }
.icon-minion-49 { background-position: -360px -800px }
.icon-minion-490 { background-position: -400px -800px }
.icon-minion-491 { background-position: -440px -800px }
.icon-minion-492 { background-position: -480px -800px }
.icon-minion-493 { background-position: -520px -800px }
.icon-minion-494 { background-position: -560px -800px }
.icon-minion-495 { background-position: -600px -800px }
.icon-minion-496 { background-position: -640px -800px }
.icon-minion-497 { background-position: -680px -800px }
.icon-minion-498 { background-position: -720px -800px }
.icon-minion-499 { background-position: -760px -800px }
.icon-minion-5 { background-position: -800px -800px }
.icon-minion-50 { background-position: -840px 0px }
.icon-minion-500 { background-position: -840px -40px }
.icon-minion-501 { background-position: -840px -80px }
.icon-minion-502 { background-position: -840px -120px }
.icon-minion-503 { background-position: -840px -160px }
.icon-minion-504 { background-position: -840px -200px }
.icon-minion-505 { background-position: -840px -240px }
.icon-minion-506 { background-position: -840px -280px }
.icon-minion-507 { background-position: -840px -320px }
.icon-minion-508 { background-position: -840px -360px }
.icon-minion-509 { background-position: -840px -400px }
.icon-minion-51 { background-position: -840px -440px }
.icon-minion-510 { background-position: -840px -480px }
.icon-minion-511 { background-position: -840px -520px }
.icon-minion-512 { background-position: -840px -560px }
.icon-minion-513 { background-position: -840px -600px }
.icon-minion-514 { background-position: -840px -640px }
.icon-minion-515 { background-position: -840px -680px }
.icon-minion-516 { background-position: -840px -720px }
.icon-minion-517 { background-position: -840px -760px }
.icon-minion-518 { background-position: -840px -800px }
.icon-minion-519 { background-position: 0px -840px }
.icon-minion-52 { background-position: -40px -840px }
.icon-minion-520 { background-position: -80px -840px }
.icon-minion-521 { background-position: -120px -840px }
.icon-minion-522 { background-position: -160px -840px }
.icon-minion-523 { background-position: -200px -840px }
.icon-minion-524 { background-position: -240px -840px }
.icon-minion-525 { background-position: -280px -840px }
.icon-minion-526 { background-position: -320px -840px }
.icon-minion-527 { background-position: -360px -840px }
.icon-minion-528 { background-position: -400px -840px }
.icon-minion-53 { background-position: -440px -840px }
.icon-minion-530 { background-position: -480px -840px }
.icon-minion-531 { background-position: -520px -840px }
.icon-minion-532 { background-position: -560px -840px }
.icon-minion-533 { background-position: -600px -840px }
.icon-minion-534 { background-position: -640px -840px }
.icon-minion-535 { background-position: -680px -840px }
.icon-minion-537 { background-position: -720px -840px }
.icon-minion-538 { background-position: -760px -840px }
.icon-minion-539 { background-position: -800px -840px }
.icon-minion-54 { background-position: -840px -840px }
.icon-minion-55 { background-position: -880px 0px }
.icon-minion-56 { background-position: -880px -40px }
.icon-minion-57 { background-position: -880px -80px }
.icon-minion-58 { background-position: -880px -120px }
.icon-minion-59 { background-position: -880px -160px }
.icon-minion-6 { background-position: -880px -200px }
.icon-minion-60 { background-position: -880px -240px }
.icon-minion-61 { background-position: -880px -280px }
.icon-minion-62 { background-position: -880px -320px }
.icon-minion-63 { background-position: -880px -360px }
.icon-minion-64 { background-position: -880px -400px }
.icon-minion-65 { background-position: -880px -440px }
.icon-minion-66 { background-position: -880px -480px }
.icon-minion-67 { background-position: -880px -520px }
.icon-minion-7 { background-position: -880px -560px }
.icon-minion-71 { background-position: -880px -600px }
.icon-minion-75 { background-position: -880px -640px }
.icon-minion-76 { background-position: -880px -680px }
.icon-minion-77 { background-position: -880px -720px }
.icon-minion-78 { background-position: -880px -760px }
.icon-minion-79 { background-position: -880px -800px }
.icon-minion-8 { background-position: -880px -840px }
.icon-minion-80 { background-position: 0px -880px }
.icon-minion-81 { background-position: -40px -880px }
.icon-minion-82 { background-position: -80px -880px }
.icon-minion-83 { background-position: -120px -880px }
.icon-minion-84 { background-position: -160px -880px }
.icon-minion-85 { background-position: -200px -880px }
.icon-minion-86 { background-position: -240px -880px }
.icon-minion-87 { background-position: -280px -880px }
.icon-minion-88 { background-position: -320px -880px }
.icon-minion-89 { background-position: -360px -880px }
.icon-minion-9 { background-position: -400px -880px }
.icon-minion-90 { background-position: -440px -880px }
.icon-minion-91 { background-position: -480px -880px }
.icon-minion-92 { background-position: -520px -880px }
.icon-minion-93 { background-position: -560px -880px }
.icon-minion-94 { background-position: -600px -880px }
.icon-minion-95 { background-position: -640px -880px }
.icon-minion-96 { background-position: -680px -880px }
.icon-minion-97 { background-position: -720px -880px }
.icon-minion-98 { background-position: -760px -880px }
.icon-minion-99 { background-position: -800px -880px }