body {
  background: #222;
  color: #eee;
  font: 14px sans-serif;
  line-height: 1.5;
  cursor: default;
}

* {
  box-sizing: border-box;
}

p {
  margin: 6px 0 12px 0;
}

a {
  color: #00B7FF;
  transition: color, background 0.09s;
}

a:hover {
  color: #9ef;
  transition: color, background 0.03s;
}

input, select, button {
  padding: 6px 9px;
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px #777;
  color: #fff;
  vertical-align: bottom;
  transition: color, background 0.09s;
}

input, select {
  width: 100%;
  border-bottom-width: 3px;
}

input:hover, input:active, input:focus,
select:hover, select:active, select:focus,
button:hover, button:active, button:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: #999;
  transition: color, background 0.03s;
}
input[type=radio], input[type=checkbox] {
  width: auto;
  margin: 4px 1px 4px 4px;
}
button[disabled] {
  border-color: transparent;
}

select {
  padding: 5px 12px;
}

::placeholder {
  color: #bbb;
}

input[type=submit], input[type=button], button {
  width: auto;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  padding: 12px 15px;
  margin-top: -1px;
  letter-spacing: 1px;
  cursor: pointer;
}

button.LinkButton {
  background: none;
  padding: 3px;
  border: none;
  text-decoration: underline;
  text-transform: none;
  letter-spacing: 0;
  float: right;
  color: #99a;
  &:hover {
    color: #eef;

  }
}

form {
  background: #444446;
  padding: 12px;
}

.FormButtons {
  text-align: center;
  padding: 12px;
}

label {
  display: block;
  padding: 6px 0;
}

.OptionGroup {
  font-weight: bold;
  label {
    font-weight: normal;
    padding: 3px 0 3px 6px;
    cursor: pointer;
  }
  label:hover {
    background: rgba(0,0,0,0.15);
  }
}

optgroup, option {
  color: #000;
}

$radioSize: 20px;
$radioBorder: #999;
//$radioBorder: #D1D7E3;
$radioActive: #5D9BFB;
//$radioActive: #66CCFF;

.OptionGroup label {
  //margin: 16px 0;
  //display: block;
  //cursor: pointer;
  input {
    display: none;
    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      &:before,
      &:after {
        content: '';
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 25%;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: #999;
        transition: background .1s ease, transform .2s cubic-bezier(.175, .885, .32, 2);
      }
      &:after {
        background: #CCC;
        transform: scale(.78);
        transition: transform .3s cubic-bezier(.175, .885, .32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: $radioActive;
      }
      &:after {
        transform: scale(.4);
        transition: transform .15s ease;
      }
    }
  }
  &:hover {
    input {
      & + span {
        &:before {
          //transform: scale(.92);
        }
        &:after {
          //transform: scale(.74);
        }
      }
      &:checked + span {
        &:after {
          //transform: scale(.4);
        }
      }
    }
  }
}

audio {
  margin: 6px 0;
  width: 100%;
}

.icon {
  display: inline-block;
}
.MountRow-Icon {
  position: relative;
}
.IconList {
  line-height: 1;
}
.IconList > * {
  padding: 1px;
  margin: 2px;
  opacity: 0.9;
  position: relative;
}
.IconList a:hover {
  opacity: 1;
}
.IconDecoration {
  position: absolute;
  font-size: 20px;
  opacity: 0.5;
  bottom: 6px;
  right: -1px;
  color: #fff;
  text-shadow: 1px 2px 4px #000;
}
.IconDecoration-Unobtainable {
  opacity: 1;
  animation-name: IconList-Unobtainable;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
@keyframes IconList-Unobtainable {
  0% {opacity: 1}
  60% {opacity: 0.6}
  100% {opacity: 1}
}
.IconList-Has .icon {
  opacity: 0.25;
}
.FishIcon .icon {
  opacity: 0.12;
}
.FishIcon:after {
  content: '';
  position: absolute;
  left: 1px;
  bottom: 4px;
  width: 40px;
  height: 40px;
  opacity: 0.03;
  background: #1e90ff;
}
.IconList-Has.FishIcon .icon {
  opacity: 1;
}
.IconList-Has.FishIcon:after {
  content: none;
}

.gacha {
  padding: 3px 3px 1px 3px;
  //background: #444;
  //width: 46px;
  //height: 46px;
  border-radius: 4px;
  display: inline-block;
  line-height: 1em;
}
.gacha0 { // gray
  background: #444;
}
.gacha1 { // silver
  //background: #bbd;
  background: #444;
}
.gacha5 { // blue
  //background: linear-gradient(340deg, rgb(88, 145, 241) 0%, rgb(160, 212, 232) 29%, rgb(88, 145, 241) 100%);
  //background: #bbd;
  background: #444;
}
.gacha10 { // green
  //background: linear-gradient(340deg, rgba(101,242,101,1) 0%, rgba(155,226,196,1) 29%, rgba(101,242,101,1) 100%);
  background: linear-gradient(340deg, rgb(88, 145, 241) 0%, rgb(160, 212, 232) 29%, rgb(88, 145, 241) 100%);
}
.gacha20 { // purple
  background: linear-gradient(340deg, rgba(200,90,255,1) 0%, rgba(246,190,255,1) 29%, rgba(200,90,255,1) 100%);
}
.gacha30 { // gold
  background: linear-gradient(340deg, rgba(255,236,111,1) 0%, rgba(246,179,33,1) 26%, rgba(255,236,111,1) 100%);
}
/*.gacha50 { // rainbow
  //background-image: linear-gradient(319deg, #91d370 0%, #bca0ff 37%, #f2cd54 100%);
  background: linear-gradient(
                  340deg,
                  rgba(255, 0, 0, 1) 0%,
                  rgba(255, 154, 0, 1) 10%,
                  rgba(208, 222, 33, 1) 20%,
                  rgba(79, 220, 74, 1) 30%,
                  rgba(63, 218, 216, 1) 40%,
                  rgba(47, 201, 226, 1) 50%,
                  rgba(28, 127, 238, 1) 60%,
                  rgba(95, 21, 242, 1) 70%,
                  rgba(186, 12, 248, 1) 80%,
                  rgba(251, 7, 217, 1) 90%,
                  rgba(255, 0, 0, 1) 100%
  );
  background-size: 200% 200%;
  animation: gacha70animation 4s linear infinite;
}*/
.gacha50, .gacha70 { // rainbow
  //background-image: linear-gradient(319deg, #91d370 0%, #bca0ff 37%, #f2cd54 100%);
  background: linear-gradient(
    340deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 5%,
    rgba(208, 222, 33, 1) 10%,
    rgba(79, 220, 74, 1) 15%,
    rgba(63, 218, 216, 1) 20%,
    rgba(47, 201, 226, 1) 25%,
    rgba(28, 127, 238, 1) 30%,
    rgba(95, 21, 242, 1) 35%,
    rgba(186, 12, 248, 1) 40%,
    rgba(251, 7, 217, 1) 45%,
    rgba(255, 0, 0, 1) 50%,
    rgba(255, 154, 0, 1) 55%,
    rgba(208, 222, 33, 1) 60%,
    rgba(79, 220, 74, 1) 65%,
    rgba(63, 218, 216, 1) 70%,
    rgba(47, 201, 226, 1) 75%,
    rgba(28, 127, 238, 1) 80%,
    rgba(95, 21, 242, 1) 85%,
    rgba(186, 12, 248, 1) 90%,
    rgba(251, 7, 217, 1) 95%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 200% 200%;
  animation: gacha70animation 4s linear infinite;
}
@keyframes gacha70animation {
  0% { background-position: 0% 0%};
  100% { background-position: 100% 100%};
}
a.gacha {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    height: 100%;
    background: transparent;
    border-radius: 4px;
  }
}
a.gacha:hover {
  //background: #fff8cf;
  //border: 3px solid #fff;
  &:after {
    background: radial-gradient(rgba(249,242,201,0.1) 50%, #f9f2c9);
  }
}


.PageContainer {
  //max-width: 1200px;
  //margin: 0 auto;
  background: #333;
  border: 2px solid #111;
  border-top: none;
  padding-top: 48px;
}

.PageHeader {
  background: url(/images/header.2.jpg);
  background-position: top right;
  height: 220px;
  position: relative;
}

.PageHeader2 {
  height: 48px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: #111116;
  border-bottom: 1px solid #111;
  z-index: 2000;
  box-shadow: 0 0 6px rgba(0,0,0,0.4);
}

h1 {
  font-family: bickham-script-pro-3, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 76px;
  line-height: 36px;
  margin: 24px 0 6px 0;
  color: #666;
}

h2 {
  color: #888;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 24px 0 0 0;
}

h3 {
  color: #777;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 12px 0 0 0;
}

h2:first-child {
  margin-top: 0;
}

.fontellocolor-red {
  color: #f24;
}

.PageHeader h1 {
  margin: 0;
  padding: 68px 0 0 48px;
  font-size: 98px;
  line-height: 36px;
  color: #fff;
}

a.PageHeaderName {
  display: inline-block;
  position: relative;
  height: 48px;
  width: 200px;
  overflow: hidden;
  padding: 4px 9px;
  img {
    opacity: 0.8;
  }
}

.PageHeader2 h1 {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 9px;
  top: 7px;
  font-size: 42px;
  line-height: 36px;
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
  height: 36px;
}

.PageHeader h1 a, .PageHeader2 h1 a {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 9px;
  top: 8px;
  font-size: 42px;
  line-height: 36px;
  color: #ddd;
  text-decoration: none;
  height: 36px;
  display: block;
}

.PageHeader h1 a:hover, .PageHeader2 a:hover h1, .PageHeader2 a:hover img {
  opacity: 1;
}

.PageHeader .PageSubheader {
  margin: 0;
  padding: 0 0 0 70px;
  font-size: 18px;
  text-transform: lowercase;
  font-style: italic;
  color: #c1e0fb;
}

.PageHeader2 .PageSubheader {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 24px;
  top: 28px;
  font-size: 11px;
  text-transform: lowercase;
  font-style: italic;
  color: #fff;
  opacity: 0.6;
}

.PageHeader2 a:hover .PageSubheader {
  color: #bbb;
}

.PageNav {
  position: absolute;
  left: 200px;
  bottom: 0;
  //width: 100%;
  //background: rgba(0, 0, 0, 0.6);
  //border-top: 1px solid #111;
  //border-bottom: 1px solid #111;
}

.PageNav > div {
  display: inline-block;
}

.PageNavLink, .PageNavLink-Mobile {
  display: inline-block;
  padding: 16px 24px 14px 24px;
  color: #eee;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
}
.PageNavLink-Mobile {
  display: none;
}

.PageNavLink:hover {
  //background: rgba(0, 0, 0, 0.4);
  color: #fff;
  background: #2c2c2c;
}

.PageNav-Secondary {
  left: auto;
  display: inline;
  width: auto;
  right: 0;
  background: none;
}

.PageNavInner {
  position: relative;
}
.PageNav-Dropdown {
  visibility: hidden;
  border: 1px solid #333;
  position: absolute;
  right: 3px;
  top: 48px;
  background: #000;
  padding: 12px;
  box-shadow: 0 0 6px rgba(0,0,0,0.5);
  z-index: 9999;
}

.PageNav > div:hover .PageNav-Dropdown {
  visibility: visible;
}

.PageNav-Dropdown .PageNavLink {
  text-transform: none;
  display: block;
  padding: 9px 24px 9px 18px;
  white-space: nowrap;
  //width: 100%;
}

.PageNav-Dropdown .PageNavLink:hover {
  background: #494949;
}
.SideMenu-Char-Mobile {
  display: none;
}

@media(max-width: 900px) {
  .PageHeader2 {
    height: auto;
    width: 100vw;
    text-align: left;
    padding-top: 6px;
    background: rgba(0, 102, 170, 0.9);
  }

  .PageHeaderName {
    //display: none !important;
    //position: absolute;
    top: 0;
    left: 6px;
  }
  /*.PageNav {
    left: 0;
    position: relative;
    display: flex;
  }
  .PageNav a {
    flex: 1 1 auto;
    //padding: 12px 0;
    border-top: 1px solid #059;
    border-right: 1px solid #059;
  }
  .PageNav-Secondary {
    //display: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
  }
  .PageNav-Secondary a {
    padding: 12px 18px;
    border-left: 1px solid #059;
  }
  .PageNav .PageNav-Text {
    display: block;
  }*/
  .PageNav .PageNavLink {
    display: none;
  }
  .PageNav .PageNavLink-Mobile {
    display: inline-block;
    padding: 24px 24px 23px 24px;
  }
}
@media(max-width: 1199px) {
  .PageNav-MobileMenu {
    display: block;
    padding: 0 0 18px 0;
    position: relative;
    left: 0;
  }
  .PageNav-MobileMenu a {
    display: block;
    border: none;
    padding: 12px 24px;
    background: #444446;
    text-align: left;
  }
  .SideMenu-Char-Mobile {
    display: block;
    margin: 0 0 6px 0;
  }
}
.PageHeaderMobile {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 500;
  background: rgba(0,0,0,0.9);
}
.MobileNav {
  background: rgba(230,230,255,0.2);
}
.PageHeaderMobile-Content {
  padding: 12px 1px 6px 1px;
  text-align: center;
  .CharactersDropdown-Chars .SideMenu-Char {
    text-align: left;
    border-top: solid 1px #222;
  }
  .PageNav-Dropdown {
    display: block;
    border: 0;
    visibility: visible;
    position: relative;
    top: 0;
    right: 0;
    padding-top: 0;
    background: transparent;
  }
  hr {
    border:none;
    border-top: 1px solid #444;
    border-bottom: 1px solid #000;
  }
}
.PageHeaderMobile-Lang {
  display: flex;
  a {
    flex: 1 0 auto;
    padding-left: 0;
    padding-right: 0;
    &.active {
      background: rgba(230,230,255,0.2);
    }
  }
}
@media(max-width: 900px) {
  .App-MenuVisible .PageHeaderMobile {
    display: block;
  }

}

.PageContainer-Fixed {
  max-width: 1200px;
  //margin: 0 auto;
}

.PageContainer-Center {
  max-width: 1200px;
  margin: 24px auto;
}

.PageContent {
  padding: 18px;
}

.PageContent-WithMenu {
}

.PageClear {
  clear: both;
}

.PageFooter {
  //max-width: 1200px;
  padding: 24px 12px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.SideMenu {
  background: #3f3f3f;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 48px;
  //float: left;
  width: 222px;
  //padding: 12px;
  overflow-y: auto;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
}
.SideMenu-Links {
  padding-bottom: 12px;
  a {
    position:relative;
  }
  .SideMenuLinkInner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #8de;
    opacity: 0.1;
  }
}

.DetailsColumn {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: #393939;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  padding: 12px;
}
.DetailsColumn .gacha {
  display: flex;
  padding: 3px;
}
.DetailsColumn .icon {
  flex-shrink: 0;
}
.DetailsColumn h2 {
  margin-top: 0;
}
.DetailsColumn .details {
  background: rgba(0, 0, 0, 0.3);
  margin-left: 3px;
  flex-grow: 1;
  border-radius: 4px;
  overflow: hidden;
}
.DetailsColumn .gacha h2 {
  padding: 6px 6px 3px 9px;
  color: #fff;
  text-shadow: 1px 1px 2px #333;
  white-space: nowrap;
  overflow: hidden;
}
.DetailsColumn .gachaText {
  margin: 0;
  float: right;
  text-transform: uppercase;
  padding: 0 3px 1px 0;
  opacity: 0.82;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 2px #333;
}
.DetailsColumn .percentText {
  padding: 1px 9px;
  margin: 0;
  color: #fff;
  text-shadow: 1px 1px 2px #333;
}
.DetailsRewardsBox {
  background: #444446;
  padding: 9px 15px;
  margin: 9px 0;
  display: flex;
  //justify-content: space-evenly;
  font-weight: bold;
  div {
    flex-grow: 1;
  }
}
.PageContent {
}

.SideMenu a.SideMenu-Group {
  background: #404040;
  padding: 7px 6px 7px 6px;
  text-transform: uppercase;
  font-size: 0.82em;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  border-top: 12px solid #3f3f3f;
}

.SideMenu a {
  display: block;
  background: #3f3f3f;
  padding: 4px 6px 4px 18px;
  text-decoration: none;
  color: #ccc;
  border-top: 1px solid #3f3f3f;
}

.SideMenu a:hover,
.SideMenu a.current {
  background: #0066aa;
  color: #fff;
}
.SideMenu-UserLinks {
  text-align: center;
  display: flex;
  //position: absolute;
  //left: 0;
  //bottom: 0;
  //right: 0;
  a {
    flex-grow: 1;
    padding: 6px;
    border-right: 2px solid #555;
    background: #444;
  }
  a:last-child {
    border-right: none;
  }
  i {
    display: block;
  }
}
.SideMenu-Patreon {
  margin-top: 12px;
  background: #444;
  border-top: solid 1px #555;
  border-bottom: solid 1px #555;
  padding: 6px 0 6px 0;
  a {
    display: flex;
    align-items: center;
    //background: #393939;
    background: #444;
    border: none;
    padding: 9px 6px 9px 18px;

    img {
      flex: 0;
    }

    p {
      flex: auto;
      margin: 0;
      padding-left: 3px;
    }
  }
}

.DetailsColumnClose {
  display: block;
  padding: 12px 9px;
  background: #0066aa;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 12px;
}

@media(min-width: 900px) {
  .PageContent-WithDetails {
    //position: fixed;
    //top: 48px;
    //left: 0;
    //left: 222px;
    //bottom: 0;
    //overflow: auto;
    //background: #303030;
    //padding: 12px;
    padding-right: 480px;
  }
  .DetailsColumn {

    width: 480px;
  }
  .DetailsColumnClose {
    display: none;
  }
}


@media(max-width: 900px) {
  .notMobile {
    display: none;
  }
  .PageContainer {
    padding-top: 60px;
  }
  .DetailsColumn {
    right: -100%;
    top: 0;
    transition: right 0.3s;
    z-index: 2000;
    width: 100%;
  }
  .DetailsColumn-MobileVisible {
    right: 0;
  }
  .PageHeader2 {
    //transition: top 0.5s;
    //top: -200px;
  }
  .App-MenuVisible .PageHeader2 {
    //top: 0;
  }
}
@media(min-width: 1200px) {
  .SideMenu {
    //float: left;
    //display: block;
    z-index: 2;
  }
  .PageContent-WithMenu {
    padding-left: 236px;
  }
  .slideout-link {
    display: none;
  }
  .mobileOnly {
    display: none;
  }
  a.PageNav-MenuToggle {
    display: none;
  }

}
@media(max-width: 1199px) {
  .SideMenu {
    left: -222px;
    top: 0;
    transition: left 0.3s;
    z-index: 2000;
  }
  .App-BurgerExpanded .SideMenu {
    left: 0;
  }
  .BurgerBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    content: '';
    display: block;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.3s ease-in-out;
    pointer-events: none;
    z-index: 2000;
  }
  .BurgerBackground .PageNav-MenuToggle {
    display: block;
    padding-left: 220px;
    text-align: center;
    padding-top: 48px;
    font-size: 2em;
    color: #fff;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    .PageNav-MenuToggleText {
      text-transform: uppercase;
      font-size: 0.5em;
      vertical-align: 4px;
    }
  }
  .App-BurgerExpanded .BurgerBackground {
    background-color: rgba(85,85,85,.8);
    pointer-events: auto;
    .PageNav-MenuToggle {
      opacity: 1;
    }
  }

}

.userToast {
  position: fixed;
  right: 12px;
  top: 60px;
  background: #900;
  color: #fff;
  padding: 6px 12px;
  z-index: 1999;
  pointer-events: none;
}


.CharactersDropdown {
  //display: flex;
  left: auto;
  width: 600px;
  padding-bottom: 6px;
  @media(min-width: 900px) {
    max-height: calc(100vh - 72px);
    overflow-y: scroll;
  }
}
.CharactersDropdown-CharsContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}
.CharactersDropdown-Chars {

}
.CharactersDropdown-Chars .SideMenu-Char {
  background: transparent;
  border-bottom: none;
  border-top: 1px solid #333;
  display: block;
  color: #fff;
  cursor: pointer;
  padding: 3px 6px 15px 3px;
  text-decoration: none;
}
.CharactersDropdown-Chars .SideMenu-Char:hover {
  background: #333;
}
.CharactersDropdown-Chars h3 {
  margin: 0;
}
.CharactersDropdown-Search {
  //flex-basis: 100%;
  border-top: 1px solid #333;
  padding-top: 6px;
}
.CharactersDropdown-Search, .CharactersDropdown-Search a {
  display: block;
}


.LoadingOverlay {
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  padding: 170px 24px 48px 24px;
  text-align: center;
  position: fixed;
  left: 6px;
  top: 52px;
  right: 6px;
  bottom: 6px;
  pointer-events: none;
  font-size: 2em;
  z-index: 5000;
}
@media(min-width: 900px) {
  .LoadingOverlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .PageContent-WithMenu .LoadingOverlay {
    left: 222px;
  }
}
.LoadingOverlay-Visible {
  visibility: visible;
}
.LoadingOverlay-Visible i {

}

.BgLoading {
  background: #fcc;
  color: #e00;
  text-align: center;
  font-weight: bold;
  padding: 12px 24px;
  border: 1px solid #900;
}
.BgLoading-Icon {
  font-size: 2em;
}

@media(min-width: 900px) {
  .BigSummaryGrid {
    display: flex;
    & > div {
      width: 50%;

      &:first-child {
        padding-right: 12px;
      }

      &:last-child {
        padding-left: 12px;
      }
    }
  }
}

.FilterBox, .PaginationContainer {
  background: #444446;
  padding: 9px 6px 3px 6px;
  margin-bottom: 12px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  display: flex;
}
.FilterBox {
  flex-wrap: wrap;
}
.PaginationContainer {
  margin-top: 12px;
  justify-content: space-between;
}
.PaginationContainer-Pages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FilterBox h2 {
  margin: 0;
  line-height: 1em;
  padding: 0 3px;
  //align-self: center;
}
@media(min-width: 900px) {
  .FilterBoxContainer {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
  .FilterBox-Small {
    margin: 0 24px 0 0;
  }
  .FilterBox-Small:last-child {
    margin: 0 0 0 0;
  }
  .FilterBox h2 {
    display: inline-block;
  }
}

.FilterBox a,
.FilterBox button,
.PaginationContainer button{
  display: inline-block;
  padding: 5px 12px;
  margin: 0 0 6px 6px;
  //margin-bottom: 3px;
  text-decoration: none;
  background: #555;
  color: #ddd;
  //margin-left: 6px;
  position: relative;
  &[disabled] {
    background: none;
    opacity: 0.2;
    pointer-events: none;
  }
}
.FilterBox button,
.PaginationContainer button {
  border: 0;
  text-transform: none;
  font-size: inherit;
  letter-spacing: 0;
  padding: 9px 12px;
  outline: none;
  i {
    margin: 0 -6px;
  }
}
.PaginationContainer button {
  font-weight: bold;
}
.FilterBox-IconFilters a,
.FilterBox-IconFilters button {
  padding: 3px 6px 0 6px;
}
.FilterBox-UiIconFilters a, .FilterBox-UiIconFilters button {
  padding: 0;
  transition: none;
  .IconDecoration {
    bottom: -3px;
    opacity: 0;
    pointer-events: none;
  }
  &.current .IconDecoration {
    opacity: 1;
  }
  .icon {
    opacity: 0.4;
  }
  &.current .icon {
    opacity: 1;
  }
  &:hover .icon {
    opacity: 0.8;
  }
}

.FilterBox a:hover, .FilterBox a.current,
.FilterBox button:hover, .FilterBox button.current,
.PaginationContainer button:hover, .PaginationContainer button.current {
  background: #77777f;
  color: #eee;
}

.FilterProgressContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  //height: 3px;
  height: 100%;
  pointer-events: none;
}

.FilterProgressInner {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #88ddee22;
  border-bottom: 3px solid #8de;
  height: 100%;
  pointer-events: none;
  /*&:after {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #8df;
    height: 9px;
    width: 100%;

  }*/
}
.FilterProgressInner-Complete {
  background: #66aa6622;
  border-bottom: 3px solid #6a6;

}


@media(min-width: 600px) {
  .ImageBox {
    display: flex;
    //padding: 6px 0 18px 0;
    padding: 12px 12px 0 12px;
    align-items: flex-start;
    background: #2f2f30;
    margin-bottom: 12px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  }
  .ImageBox.ImageBoxTransparent {
    background: transparent;
    box-shadow: none;
  }
  .ImageBox > img {
    //margin: 0 18px 0 0;
    width: 250px;
    height: 320px;
    //margin-bottom:-2px;
    //border-bottom: 3px solid #292929;
  }
  .ImageBox > div {
    padding: 0 6px 12px 12px;
  }
  .ImageBox p:last-child {
    margin-bottom: 0;
  }
}
.InfoBox {
  display: flex;
  align-items: center;
  background: #222;
  color: #fff;
}
.InfoBox > div {
  padding: 12px;
}
.InfoBox-Small > div {
  padding: 2px 4px;
  font-size:0.95em;
}
.InfoBox-Error {
  background: #fcc;
  color: #e00;
}
.InfoBox-Success {
  background: #cfc;
  color: #0b0;
}

#searchResults {

}

#searchResults td {
  padding: 6px 9px 6px 0;
  line-height: 1.2;
}

#searchResults img {
  width: 48px;
  height: 48px;
  border-radius: 6px;
}

#searchResults a {
  font-size: 1.2em;
}

#searchResults .serverName {
  font-style: italic;
}


.SideMenu-Char {
  background: #3f3f3f;
  padding: 6px 6px 18px 6px;
  border-bottom: 1px solid #333;
}

.SideMenu-Icon {
  width: 48px;
  //border-radius: 24px;
  float: left;
  border: 1px solid #222;
  border-radius: 4px;
}
.SideMenu-Patreon .SideMenu-Icon {
  border-color: gold;
}

.SideMenu-CharName {
  display: block;
  font-size: 1em;
  padding-left: 51px;
  padding-top: 6px;
  white-space: nowrap;
}

.SideMenu-CharWorld {
  display: block;
  font-size: 0.75em;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 51px;
}

.SideMenu-Deselect {
  a {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px 6px;
  }
}

.Char-BackgroundName {
  //position: absolute;
  //right: 32px;
  //top: 78px;
  float: right;
  padding-right: 32px;
  margin: 24px 0 0 0;
  //margin: 0;
  color: #555;
  //z-index: 1;
}

.Char-Race {
  //position: absolute;
  //right: 52px;
  //top: 108px;
  float: right;
  clear: right;
  color: #777;
  font-style: italic;
  padding-right: 52px;
  //z-index: 1;
}

.Char-NameContainer {
  background: #2c2c2c;
  width: 50%;
  padding: 6px 12px;

}

.Char-Name {
  font-size: 1.6em;
  vertical-align: middle;
}

.Char-World {
  text-transform: uppercase;
  letter-spacing: 1px;
  vertical-align: middle;
  padding: 0 24px;
}

.Char-Portrait {
  max-width: 100%;
}

.MountTable {
  border-collapse: collapse;
  width: 100%;
}

.MountRow {
  .RankingRow-Number {
    font-size: 1.3em;
    font-weight: bold;
    line-height: 0.9em;
    white-space: nowrap;
  }
}

.MountRow td {
  padding: 4px 6px;
  text-align: left;
  border-top: 1px solid rgba(255,255,255,0.2);
}

.MountRow:nth-child(odd) {
  //background: #3c3c3c;
}
.MountRow-Have {
  background: #2c492c;
}
.MountRow-Have:nth-child(odd) {
  //background: #3c5c3c;
}
.MountRow td.MountRow-Center {
  text-align:center;
}

td.MountRow-Check {
  padding: 0;
  width: 50px;
}
.MountRow-Check button {
  margin: -3px;
  margin-left: 3px;
  padding: 12px 9px;
  background: none;
  border: none;
  outline: none;
  border-radius: 42%;
}
.MountRow-Check button:hover {
  background: rgba(255,255,255,0.15);
}
.MountRow-Check button[disabled] {
  background: none;
  cursor: default;
}
.MountRow-Check button i.animate-spin {
  opacity: 1 !important;
}
.MountRow-Check i {
  display: block;
  padding: 6px;
  opacity: 0.1;
}
.MountRow-Have .MountRow-Check i,
.Details-Have .MountRow-Check i{
  opacity: 1;
}

.MountRow-Icon {
  width: 48px;
}

.MountRow-Name {
  font-size: 1.2em;
  font-weight: bold;
  //white-space: nowrap;
  line-height: 1em;
}

.MountMinionDetails-Tag {
  text-transform: uppercase;
  font-size: 0.82em;
  padding: 0 9px;
}
.UnobtainableTag {
  color: #d35;
}

.MountRow td.MountRow-Reward {
  padding: 3px 24px;
  white-space: nowrap;
}

.MountRow-Title {
  font-style: italic;
}

.MountRow-Item {

}

.MountRow td.MountRow-Value {
  padding: 3px 24px;
  text-align: center;
  //font-size: 1.3em;
  //font-weight: bold;
  white-space: nowrap;
}

.MountRow-Obtained td {
  opacity: 1;
}

.MoogleRow-hair .MountRow-Icon {

}
.MoogleRow-hair .MountRow-Icon .icon-hair {
  scale: 42%;
  position: absolute;
  top: 4px;
  left: 6px;
  transform-origin: top left;
}

.RankingTable {
  border-collapse: collapse;
  width: 100%;
}

.RankingRow {

  td {
    padding: 6px 9px;
    border-bottom: 1px solid #111;
  }

  .RankingRow:last-child td {
    border: none;
  }

  .RankingRow:nth-child(odd) {
    /*background: #3c3c3c;*/
  }

  .RankingRow-Rank {
    width: 72px;
    text-align: center;
    font-size: 1.8em;
    white-space: nowrap;
    padding-right: 0;
  }

  .RankingRow-RankChange {
    padding-left: 0;
    width: 24px;
    text-align: left;
    font-size: 1.2em;
    white-space: nowrap;
    color: #777;
    .fontello-up-open {
      color: #693;
    }
    .fontello-down-open {
      color: #c33;
    }
  }

  .RankingRow-Icon {
    width: 64px;
  }
  .RankingRow-ExtraIcon {
    width: 48px;
    opacity: 0.8;
  }

  .RankingRow-Icon img {
    width: 64px;
    height: 64px;
    border: 1px solid #222;
    border-radius: 4px;
  }
  .RankingRow-Patreon .RankingRow-Icon img {
    border-color: gold;
  }

  .RankingRow-Text {
    text-align: left;
  }

  .RankingRow-Name {
    font-size: 1.2em;
    font-weight: bold;
    white-space: nowrap;
    line-height: 1em;
  }

  .RankingRow-Values {
    width: 128px;
    text-align: center;
  }

  .RankingRow-Number {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 0.9em;
    white-space: nowrap;
  }
  .RankingRow-All {
    //line-height: 0.8em;
  }
  .RankingRow-NumberChange {
    font-weight: bold;
    color: #777;
  }

}

.Mount-Icon {
  margin-right: 6px;
  vertical-align: middle;
}

.Mount-Meta {
  background: #444446;
  padding: 6px 12px;
}

@media(min-width: 900px) {
  .Mount-GameDetails {
    width: 40%;
    float: left;
    padding-right: 24px;
    padding-bottom: 24px;
  }

  .Mount-SiteDetails {
    width: 60%;
    float: left;
    padding-left: 24px;
    padding-bottom: 24px;
  }
}

.MountMinionDetails-Notebook {
  float: right;
}

.TwentyFiveList, .OneHundredList {
  //float: left;
  display: inline-block;
  vertical-align: top;
  margin: 0 12px 12px 0;
  background: #2c2c2c;
  padding: 3px;
  & > a {
  }
  & > h3 {
    margin: 0 0 6px 0;
    text-align: center;
  }
}
.TwentyFiveList {
  width: 256px;

}
.OneHundredList {
  width: 466px;
}

.CompletionSummary {
  background: #292929;
  padding: 15px 21px;
  margin: 18px 0 12px 0;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
}

.CompletionSummaryContainer {
  .CompletionSummary {
    margin: 18px 0 12px 0;
  }
}

@media(min-width: 800px) {
  .CompletionSummaryContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;

    .CompletionSummary {
      flex: 1 1 auto;
      margin: 0 12px 12px 0;
    }
  }
}


.CompletionSummary h3 {
  color: #fff;
  margin: 0;
}

.CompletionSummary p {
  margin: 0;
}

.CompletionSummary-Percent {
  position: absolute;
  right: 18px;
  top: 10px;
  font-weight: bold;
  font-size: 1.8em;
}

.CompletionSummary-Numbers {
  font-size: 1.2em;
}

.CompletionSummary-Remaining {
  font-size: 0.7em;
  margin-left: 6px;
}

.CompletionSummary-WithIcon {
  padding-left: 48px;
  .icon {
    position: absolute;
    left: 6px;
    top: 50%;
    margin-top:-18px;
  }
}

.HomeHeader {
  background: url(/images/header.2.jpg);
  background-size: cover;
  background-position: 80% 0;
  //padding: 24% 0 8% 26px;
  padding: 120px 0 36px 26px;
  line-height: 1.2em;
  color: #eff;
  font-size: 1.6em;
  margin: -20px auto 24px auto;
  //margin: -20px -24px 24px -24px;
  //overflow: hidden;
  width: 100%;
}
@media(min-width: 600px) {
  .HomeHeader {
    //padding: 14% 0 4% 26px;
    padding: 180px 0 48px 26px;

    font-size: 2em;
  }
}
@media(min-width: 1200px) {
  .HomeHeader {
    width: 1200px;
  }
}
.HomeSlogan {
  font-size: 1.6em;
  //font-weight: bold;
  //font-style: italic;
}
.HomeText {

}
.HomeSidebar {
  padding-bottom: 24px;
}
@media(min-width: 600px) {
  .HomeSidebar {
    width: 290px;
    float: right;
    padding-left: 24px;
  }
}
.HomeSidebar h2 {
  margin: 0 0 12px 0;
}
.HomeSidebar a {
  display: block;
  margin-bottom: 18px;
  background: #4f4f4f;
  color: #ddd;
  text-decoration: none;
  padding: 9px 12px 9px 9px;
}
.HomeSidebar a:hover {
  background: #666;
}
.HomeSidebar img, .HomeSidebar i {
  float: left;
  margin: 9px 6px 0 0;
}
.HomeSidebar i {
  font-size: 1.4em;
  margin: 6px 6px 0 0;
}
.HomeSidebar a p {
  font-weight: bold;
  margin: 0;
}



.TimelineDate {
  border-bottom: 1px solid #666;
  padding-top: 48px;
  text-align: right;
}
div:first-child > .TimelineDate {
  padding: 0;
}
.TimelineContainer {
  display: flex;
  justify-content: flex-start;
  //align-items: stretch;
  //align-content: stretch;
  flex-wrap: wrap;
}
.Timeline {
  padding-right:36px;
}
.TimelineRow td {
  padding: 2px 3px;
  border-top: 1px solid #4f4f4f;
}
.TimelineRow-Have {
  background: #2c492c;
}
.TimelineRow:nth-child(odd) {
  //background: #404040;
}
.TimelineRow-Icon {
  padding: 0;
}
.TimelineRow-Check i {
  display: block;
  //padding: 12px 6px 12px 12px;
  opacity: 0.1;
}
.TimelineRow-Have .TimelineRow-Check i {
  opacity: 1;
}
.TimelineRow-Icon .icon {
  transform: scale(0.5);
  margin: -9px -10px -15px -10px;
}
td.TimelineRow-Value {
  padding-left: 12px;
}


.DataLoading {
  position: fixed;
  width: 300px;
  height: 100px;
  right: 50%;
  margin-right: -150px;
  bottom: 0;
  text-align: left;
  vertical-align: top;
  background: rgba(0,0,0,0.6);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 9px 12px 6px 84px;
  border: 2px solid #000;
  border-bottom: 0;
  z-index: 99999;
  pointer-events: none;
  img {
    position: absolute;
    left: 4px;
    bottom: 1px;
  }
  p, h2 {
    margin: 0;
  }
}




/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111;
  border: 1px solid #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444446;
  border: 2px solid #111;
  border-top-width: 4px;
  border-bottom-width: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00B7FF;
}

::-webkit-scrollbar-button {
  border-style: solid;
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-button:vertical:decrement {
  border-width: 0 6px 10px 6px;
  border-color: transparent transparent #666 transparent;
}

::-webkit-scrollbar-button:vertical:decrement:hover {
  border-color: transparent transparent #00B7FF transparent;
}

::-webkit-scrollbar-button:vertical:increment {
  border-width: 10px 6px 0 6px;
  border-color: #666 transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:increment:hover {
  border-color: #00B7FF transparent transparent transparent;
}

::-webkit-scrollbar-button:horizontal:decrement {
  display: none;
}

::-webkit-scrollbar-button:horizontal:increment {
  display: none;
}

#badglobalerror {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50000;
  background: red;
  padding: 3px 6px;
}

.MountImageTooltip {
  display: flex;
  align-items: center;
  img {

  }
  div {
    padding: 24px;
  }
}

.Pill {
  white-space: nowrap;
  padding: 1px;
  .icon {
    transform: scale(.5);
    margin: -9px -10px -12px;
  }
}

.TriadNumbers {
  position: relative;
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-bottom: 8px;
  & > span {
    position: absolute;
    height: 10px;
    width: 10px;
  }
  .TriadNumbersTop {
    top: 0;
    left: 13px;
  }
  .TriadNumbersLeft {
    top: 13px;
    left: 0;
  }
  .TriadNumbersRight {
    top: 13px;
    right: 0;
  }
  .TriadNumbersBottom {
    bottom: 0;
    left: 13px;
  }
}




.CharProfile {
  column-count: 1;
  column-gap: 24px;
  margin: -18px;
  padding: 18px;
  position:relative;
}
.CharProfile-HasBanner {
  padding-top: 220px;
}
.CharProfileBg {
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;

  background-size: cover;
}
.CharProfileBgFade {
  position:absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, #333, transparent 100%);
}

@media only screen and (min-width: 650px) {
  .CharProfile {
    column-count: 2;
  }
  .CharProfileTwo {
    column-count: 2;
  }
}
@media only screen and (min-width: 1200px) {
  .CharProfile {
    column-count: 3;
  }
}

.CharProfileBox {
  //display: flex;
  //flex-direction: column;
  //-webkit-column-break-inside: avoid;
  break-inside: avoid;
  background: #1c1c1c55;
  //background: #19191ce6;
  margin-bottom: 24px;
  position: relative;

  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
}
.CharProfileBox h2 {
  //color: #fff;
  margin: 0;
  padding: 9px 0 6px 12px;
  border-bottom: 2px solid #55555544;
}
.CharProfileBox h2 i {
  color: #fff;
}
.CharProfileBox-Content {
  padding: 9px 12px 12px 12px;

}
.CharProfileBox h3 {
  margin: 0;
}
.CharProfileBox p {
  margin: 0;
}

.CharProfileBox-Percent {
  position: absolute;
  top: 3px;
  right: 12px;
  font-size: 2em;
  font-weight: bold;
  color: #888;
}
.CharProfileBox-Rank {
  display: flex;
  //justify-content: space-around;
}
.CharProfileBox-Rank p {
  flex-grow: 1;
}
.CharProfileBox-List {
  //border-top: 2px solid #333;
  padding-top: 6px;
}
.CharProfileBox-List > .gacha,
.CharProfileBox-List > .icon {
  float: right;
  //margin: 6px;
}
.CharProfileBox-List-Name {
  //font-weight: bold;
  //padding-top: 3px;
}
.CharProfileBox-List-Description {
  text-transform: uppercase;
  font-size: 0.75em;
  letter-spacing: 1px;
}



.Admin form {
  margin: 12px 12px;
}
.Admin td {
  padding: 6px 12px;
}
.Admin .MountRow-Name {
  width: 200px;
}
.Admin input[type=checkbox] {
  width: 32px;
  height: 32px;
}
.Admin select {
  width: 150px;
}
.Admin textarea {
  width: 950px;
  height: 48px;
  margin-right: 6px;
  background: #393939;
  border: 1px solid #444;
  color: #fff;
}

.leaflet-container {
  margin: 12px auto;
  background: #222;
  label {
    padding: 6px;
    cursor: pointer;
    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
  input[type=checkbox] {
    width: auto;
    top: -2px;
  }
}
.smallmap {
  //width: 410px;
  height: 410px;
}
.bigmap {
  //width: 820px;
  height: 820px;
}
.leaflet-tooltip p {
  margin: 0;
}
.mapIconClickable {
  opacity: 0.9;
  &.mapIconHas {
    //opacity: 0.3;
  }
  &:hover {
    opacity: 1;
  }
}
.leaflet-tooltip a {
  pointer-events: all;
  &:hover {
    color: #000;
  }
}

.icon-popularity-140,
.icon-popularity-120,
.icon-popularity-100,
.icon-popularity-80 {
  background-image: url(./images/popularity-supply.png);
  background-size: 256px 128px;
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position-x: -7px;
  margin: -3px;
}
.icon-popularity-140 {background-position-y: 0px;}
.icon-popularity-120 {background-position-y: -27px;}
.icon-popularity-100 {background-position-y: -52px;}
.icon-popularity-80 {background-position-y: -77px;}

.icon-supply-130100,
.icon-supply-130,
.icon-supply-100,
.icon-supply-80,
.icon-supply-60 {
  background-image: url(./images/popularity-supply.png);
  background-size: 256px 128px;
  width: 60px;
  height: 22px;
  display: inline-block;
  background-position-x: -95px;
  margin: -3px;
}
.icon-supply-130100 {background-position-y: 0px;}
.icon-supply-130 {background-position-y: -25px;}
.icon-supply-100 {background-position-y: -50px;}
.icon-supply-80 {background-position-y: -75px;}
.icon-supply-60 {background-position-y: -100px;}

.icon-demandshift-0,
.icon-demandshift-1,
.icon-demandshift-2,
.icon-demandshift-3,
.icon-demandshift-4 {
  background-image: url(./images/popularity-supply.png);
  background-size: 256px 128px;
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position-x: -210px;
  transform:scale(1.2);
}
.icon-demandshift-0 {background-position-y: -100px;}
.icon-demandshift-1 {background-position-y: -25px;}
.icon-demandshift-2 {background-position-y: -75px;}
.icon-demandshift-3 {background-position-y: 0px;}
.icon-demandshift-4 {background-position-y: -50px;}

