
@media(min-width: 900px) {
  .SettingsContainer {
    display: flex;
    //flex-direction: column;
  }
  .SettingsColumn {
    flex-grow: 1;
  }
  .SettingsColumn:first-child {
    padding-right: 18px;
  }
}
.SettingsBox {
  background: #2f2f30;
  border: 1px solid #555;
  padding: 18px;
  //padding-bottom: 6px;
  margin-bottom: 18px;
  //flex: 50%;
  //padding: 24px;
  position: relative;
  h3 {
    margin: 0;
  }
  &.SettingsBox-Premium {
    border: 1px solid darkgoldenrod;
    &:before {
      content: "Premium";
      position: absolute;
      right: 9px;
      top: 6px;
      color: goldenrod;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}
.SettingsCharIconGroup {
  padding: 0 0 12px 0;
}
.SettingsCharIcon {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  position: relative;
  width: 96px;
  height: 96px;
  margin-right: 6px;
  overflow: hidden;
  img {
    width: 96px;
    height: 96px;
    border-radius: 3px;
  }
  input {
    display: none;
  }
  input:checked + img, img:hover {
  }
  span {
    border: 2px solid transparent;
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 96px;
    height: 96px;
    border-radius: 3px;
  }
  &.checked, &:hover {
    span {
      border: 2px solid #f9f2c9;
      background: radial-gradient(transparent 70%, #f9f2c9);
      //opacity: 0.6;
    }
  }
}
.SettingsCharColorGroup .SettingsCharIcon {
  &:before {
    content: "";
    background: #6a6;
    position: absolute;
    right: 0;
    bottom: -22px;
    width: 96px;
    height: 35px;
    transform: skewY(-15deg);
  }
}
.SettingsCharColor0 {
  background: #0066aa;
}
.SettingsCharColor1 {
  background: blueviolet;
}
.SettingsCharColor2 {
  background: tomato;
  &:before {
    background: #9d6 !important;
  }
}
.SettingsCharColor3 {
  background: hotpink;
  &:before {
    background: #9d6 !important;
  }
}
.SettingsCharColor4 {
  background: forestgreen;
}
.SettingsCharColor5 {
  background: sandybrown;
  &:before {
    background: #4b4 !important;
  }
}
.SettingsCharColor6 {
  background: darkgray;
  &:before {
    background: #6d6 !important;
  }
}

.SettingsPrefixedInput {
  padding: 9px 12px;
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px #777;
  border-bottom-width: 3px;
  color: #fff;
  transition: color, background 0.09s;
  input {
    padding: 0;
    background: none;
    display: inline-block;
    border: none;
    width: auto;
  }
  &:active, &:focus-within {
    background: rgba(255, 255, 255, 0.15);
    border-color: #999;
    transition: color, background 0.03s;
  }
}