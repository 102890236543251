
.icon-bozja {
	background-image: url(./bozja.png);
	width: 72px;
	height: 72px;
}
.icon-bozja-1 { background-position: 0px 0px }
.icon-bozja-10 { background-position: -72px 0px }
.icon-bozja-11 { background-position: 0px -72px }
.icon-bozja-12 { background-position: -72px -72px }
.icon-bozja-13 { background-position: -144px 0px }
.icon-bozja-14 { background-position: -144px -72px }
.icon-bozja-15 { background-position: 0px -144px }
.icon-bozja-16 { background-position: -72px -144px }
.icon-bozja-17 { background-position: -144px -144px }
.icon-bozja-18 { background-position: -216px 0px }
.icon-bozja-19 { background-position: -216px -72px }
.icon-bozja-2 { background-position: -216px -144px }
.icon-bozja-20 { background-position: 0px -216px }
.icon-bozja-21 { background-position: -72px -216px }
.icon-bozja-22 { background-position: -144px -216px }
.icon-bozja-23 { background-position: -216px -216px }
.icon-bozja-24 { background-position: -288px 0px }
.icon-bozja-25 { background-position: -288px -72px }
.icon-bozja-26 { background-position: -288px -144px }
.icon-bozja-27 { background-position: -288px -216px }
.icon-bozja-28 { background-position: 0px -288px }
.icon-bozja-29 { background-position: -72px -288px }
.icon-bozja-3 { background-position: -144px -288px }
.icon-bozja-30 { background-position: -216px -288px }
.icon-bozja-31 { background-position: -288px -288px }
.icon-bozja-32 { background-position: -360px 0px }
.icon-bozja-33 { background-position: -360px -72px }
.icon-bozja-34 { background-position: -360px -144px }
.icon-bozja-35 { background-position: -360px -216px }
.icon-bozja-36 { background-position: -360px -288px }
.icon-bozja-37 { background-position: 0px -360px }
.icon-bozja-38 { background-position: -72px -360px }
.icon-bozja-39 { background-position: -144px -360px }
.icon-bozja-4 { background-position: -216px -360px }
.icon-bozja-40 { background-position: -288px -360px }
.icon-bozja-41 { background-position: -360px -360px }
.icon-bozja-42 { background-position: -432px 0px }
.icon-bozja-43 { background-position: -432px -72px }
.icon-bozja-44 { background-position: -432px -144px }
.icon-bozja-45 { background-position: -432px -216px }
.icon-bozja-46 { background-position: -432px -288px }
.icon-bozja-47 { background-position: -432px -360px }
.icon-bozja-48 { background-position: 0px -432px }
.icon-bozja-49 { background-position: -72px -432px }
.icon-bozja-5 { background-position: -144px -432px }
.icon-bozja-50 { background-position: -216px -432px }
.icon-bozja-6 { background-position: -288px -432px }
.icon-bozja-7 { background-position: -360px -432px }
.icon-bozja-8 { background-position: -432px -432px }
.icon-bozja-9 { background-position: -504px 0px }