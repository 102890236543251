
.icon-fashion {
	background-image: url(./fashion.png);
	width: 40px;
	height: 40px;
}
.icon-fashion-1 { background-position: 0px 0px }
.icon-fashion-10 { background-position: -40px 0px }
.icon-fashion-11 { background-position: 0px -40px }
.icon-fashion-12 { background-position: -40px -40px }
.icon-fashion-13 { background-position: -80px 0px }
.icon-fashion-14 { background-position: -80px -40px }
.icon-fashion-15 { background-position: 0px -80px }
.icon-fashion-16 { background-position: -40px -80px }
.icon-fashion-17 { background-position: -80px -80px }
.icon-fashion-18 { background-position: -120px 0px }
.icon-fashion-19 { background-position: -120px -40px }
.icon-fashion-2 { background-position: -120px -80px }
.icon-fashion-20 { background-position: 0px -120px }
.icon-fashion-22 { background-position: -40px -120px }
.icon-fashion-23 { background-position: -80px -120px }
.icon-fashion-24 { background-position: -120px -120px }
.icon-fashion-25 { background-position: -160px 0px }
.icon-fashion-26 { background-position: -160px -40px }
.icon-fashion-27 { background-position: -160px -80px }
.icon-fashion-28 { background-position: -160px -120px }
.icon-fashion-3 { background-position: 0px -160px }
.icon-fashion-30 { background-position: -40px -160px }
.icon-fashion-31 { background-position: -80px -160px }
.icon-fashion-32 { background-position: -120px -160px }
.icon-fashion-33 { background-position: -160px -160px }
.icon-fashion-34 { background-position: -200px 0px }
.icon-fashion-35 { background-position: -200px -40px }
.icon-fashion-36 { background-position: -200px -80px }
.icon-fashion-37 { background-position: -200px -120px }
.icon-fashion-38 { background-position: -200px -160px }
.icon-fashion-39 { background-position: 0px -200px }
.icon-fashion-4 { background-position: -40px -200px }
.icon-fashion-40 { background-position: -80px -200px }
.icon-fashion-41 { background-position: -120px -200px }
.icon-fashion-42 { background-position: -160px -200px }
.icon-fashion-43 { background-position: -200px -200px }
.icon-fashion-44 { background-position: -240px 0px }
.icon-fashion-5 { background-position: -240px -40px }
.icon-fashion-6 { background-position: -240px -80px }
.icon-fashion-7 { background-position: -240px -120px }
.icon-fashion-8 { background-position: -240px -160px }
.icon-fashion-9 { background-position: -240px -200px }