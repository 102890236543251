
.icon-rewards {
	background-image: url(./rewards.png);
	width: 36px;
	height: 36px;
}
.icon-rewards-065001 { background-position: 0px 0px }
.icon-rewards-065002 { background-position: -36px 0px }
.icon-rewards-065003 { background-position: 0px -36px }
.icon-rewards-065004 { background-position: -36px -36px }
.icon-rewards-065005 { background-position: -72px 0px }
.icon-rewards-065006 { background-position: -72px -36px }
.icon-rewards-065007 { background-position: 0px -72px }
.icon-rewards-065008 { background-position: -36px -72px }
.icon-rewards-065009 { background-position: -72px -72px }
.icon-rewards-065010 { background-position: -108px 0px }
.icon-rewards-065011 { background-position: -108px -36px }
.icon-rewards-065012 { background-position: -108px -72px }
.icon-rewards-065013 { background-position: 0px -108px }
.icon-rewards-065014 { background-position: -36px -108px }
.icon-rewards-065015 { background-position: -72px -108px }
.icon-rewards-065016 { background-position: -108px -108px }
.icon-rewards-065017 { background-position: -144px 0px }
.icon-rewards-065018 { background-position: -144px -36px }
.icon-rewards-065019 { background-position: -144px -72px }
.icon-rewards-065020 { background-position: -144px -108px }
.icon-rewards-065021 { background-position: 0px -144px }
.icon-rewards-065022 { background-position: -36px -144px }
.icon-rewards-065023 { background-position: -72px -144px }
.icon-rewards-065024 { background-position: -108px -144px }
.icon-rewards-065025 { background-position: -144px -144px }
.icon-rewards-065026 { background-position: -180px 0px }
.icon-rewards-065027 { background-position: -180px -36px }
.icon-rewards-065028 { background-position: -180px -72px }
.icon-rewards-065029 { background-position: -180px -108px }
.icon-rewards-065030 { background-position: -180px -144px }
.icon-rewards-065031 { background-position: 0px -180px }
.icon-rewards-065032 { background-position: -36px -180px }
.icon-rewards-065033 { background-position: -72px -180px }
.icon-rewards-065034 { background-position: -108px -180px }
.icon-rewards-065035 { background-position: -144px -180px }
.icon-rewards-065036 { background-position: -180px -180px }
.icon-rewards-065037 { background-position: -216px 0px }
.icon-rewards-065038 { background-position: -216px -36px }
.icon-rewards-065039 { background-position: -216px -72px }
.icon-rewards-065040 { background-position: -216px -108px }
.icon-rewards-065041 { background-position: -216px -144px }